import {
  Account,
  AccountsResponse,
  CustomerResponse,
  MaxAndMinValuesResponse,
  PaymentResponse
} from '../../models/api.interface'

export const mockCustomerResponse: CustomerResponse = {
  data: {
    cards: [
      {
        customerName: 'DANIEL SALGADO PINTO SALGADO',
        cardType: 'UIO',
        cardNumber: '4931 **** **** 3737',
        balance: 100
      }
    ]
  }
}

export const emptyCustomerResponse: CustomerResponse = {
  data: {
    cards: []
  }
}

export const mockFavoriteAccount: Account = {
  hash: '3854344a64554537584154353562444c4b4d425776366f6773597365623641706655586f445870574a35553d',
  mask: '220404XXXX',
  cipher: '9Bsrixb+ccjrZFiGlIFld4TeO9lU/2oao2XyFgt2xhTWOSGMR8yIdyXVJQ+oGrhW',
  alias: 'La del sueldo',
  type: 'Ahorro',
  balance: '$ 81,915.91',
  favorite: true
}

export const mockAccount: Account = {
  hash: '3854344a64554537584154353562444c4b4d425776366f6773597365623641706655586f445870574a35553d',
  mask: '220404XXXX',
  cipher: '9Bsrixb+ccjrZFiGlIFld4TeO9lU/2oao2XyFgt2xhTWOSGMR8yIdyXVJQ+oGrhW',
  alias: 'AHO322',
  type: 'Ahorro',
  balance: '$ 81,915.91',
  favorite: false
}

export const mockAccountsResponse: AccountsResponse = {
  userContraPartyCode: '0917703209',
  serviceCode: '17288',
  serviceName: 'TARJETA PREPAGO',
  accounts: [mockAccount, mockFavoriteAccount],
  bill: {
    code: '105322391',
    counterpartyCode: '0917703209',
    paymentType: {
      code: '3',
      name: 'Sin Limite'
    },
    paymentOption: {
      code: '1',
      name: 'Débito a Cuenta',
      alias: 'DC'
    },
    details: {
      identification: '0917703209',
      customerName: 'Daniel Salgado',
      reference: '4931 **** **** 3737',
      document: '100000023843'
    }
  }
}

export const emptyAccountsResponse: AccountsResponse = {
  userContraPartyCode: '',
  serviceCode: '',
  serviceName: '',
  accounts: [],
  bill: {
    code: '',
    counterpartyCode: '',
    paymentType: {
      code: '',
      name: ''
    },
    paymentOption: {
      code: '',
      name: '',
      alias: ''
    },
    details: {
      identification: '',
      customerName: '',
      reference: '',
      document: ''
    }
  }
}

export const mockOtherCustomerResponse: AccountsResponse = {
  userContraPartyCode: '111111111',
  serviceCode: '17288',
  serviceName: 'TARJETA PREPAGO',
  accounts: [mockAccount, mockFavoriteAccount],
  bill: {
    code: '111111111',
    counterpartyCode: '1111111111',
    paymentType: {
      code: '3',
      name: 'Sin Limite'
    },
    paymentOption: {
      code: '1',
      name: 'Débito a Cuenta',
      alias: 'DC'
    },
    details: {
      identification: '1111111111',
      customerName: 'Juan Perez',
      reference: '4931 **** **** 1111',
      document: '100000023843'
    }
  }
}

export const mockPaymentResponse: PaymentResponse = {
  voucher: '123123',
  amount: '$ 10.50',
  balance: '$ 10.50',
  transactionCost: '$ 0.00',
  date: '10-01-2024',
  name: 'Nombre Apellido',
  cardNumber: '220404XXXX',
  attachment: 'base64'
}

export const emptyPaymentResponse: PaymentResponse = {
  voucher: '',
  amount: '',
  balance: '',
  transactionCost: '',
  date: '',
  name: '',
  cardNumber: '',
  attachment: ''
}

export const mockMaxAndMinValues: MaxAndMinValuesResponse = {
  maxValueRecharge: 20,
  minValueRecharge: 0.33
}
