import { Dispatch } from 'react'
import { AuthWebviewEvent } from '../../models/auth-webview-event'
import { Account } from '../../models/api.interface'
import {
  Actions,
  authenticate,
  setTitle,
  showHeader,
  expandHeader,
  setIsOther,
  setSelectedAccount,
  setRechargeAmount
} from '../actions/actions'

export const getActions = (dispatch: Dispatch<any>): Actions => {
  return {
    authenticate: (authEvent: AuthWebviewEvent) => dispatch(authenticate(authEvent)),
    setTitle: (title: string) => dispatch(setTitle(title)),
    showHeader: (isHeaderVisible: boolean) => dispatch(showHeader(isHeaderVisible)),
    expandHeader: (isHeaderExpanded: boolean) => dispatch(expandHeader(isHeaderExpanded)),
    setIsOther: (isOther: boolean) => dispatch(setIsOther(isOther)),
    setSelectedAccount: (account: Account) => dispatch(setSelectedAccount(account)),
    setRechargeAmount: (amount: string) => dispatch(setRechargeAmount(amount))
  }
}
