import { FC, ReactNode } from 'react'

import './modal.scss'
import classNames from 'classnames'

interface ModalProps {
  children: ReactNode
  center?: boolean
  onClose: () => void
}

const Modal: FC<ModalProps> = ({ children, onClose, center }) => {
  return (
    <div
      className={classNames('modal', {
        'modal--center': center
      })}
    >
      <div className="modal__outside" data-testid="modal-outside-testid" onClick={onClose} />
      <div
        className={classNames('modal__content', {
          'modal__content--center': center
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
