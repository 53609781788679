import { Navigate, Route, Routes } from 'react-router-dom'
import { Home } from '../components/screens/home/home'
import Recharge from '../components/screens/recharge/recharge'
import Confirmation from '../components/screens/confirmation/confirmation'
import DebitAccounts from '../components/screens/debit-accounts/debit-accounts'
import Voucher from '../components/screens/voucher/voucher'
import Others from '../components/screens/others/others'
import { RoutePath } from '../common/constants/route-path'
import { PichinchaDivider, PichinchaLoadingScreen, PichinchaTopbar } from '@pichincha/ds-react'
import { ALIGN } from '@pichincha/ds-core'
import Onboarding from '../components/screens/onboarding/onboarding'
import useNavigation from './use-navigation/use-navigation'

export const Navigation = () => {
  const { isHeaderExpanded, isHeaderVisible, isLoading, onBackClick, screenTitle } = useNavigation()

  if (isLoading)
    return (
      <div className="page-container" id="page-container" data-testid="loading-screen">
        <PichinchaLoadingScreen description="Espera un momento" />
      </div>
    )
  return (
    <>
      {isHeaderVisible && (
        <div className="navigation-header">
          <PichinchaTopbar
            labelTitle={screenTitle}
            iconLeft="arrow_back"
            onBackButtonPress={onBackClick}
            expanded={isHeaderExpanded}
            textAlign={'center' as ALIGN}
          />
          {!isHeaderExpanded && <PichinchaDivider variant="solid" style={{ padding: 0 }} />}
        </div>
      )}
      <div className="page-container" id="page-container" data-testid="navigation">
        <Routes>
          <Route path={RoutePath.HOME} element={<Home />} />
          <Route path={RoutePath.RECHARGE} element={<Recharge />} />
          <Route path={RoutePath.ACCOUNTS} element={<DebitAccounts />} />
          <Route path={RoutePath.CONFIRMATION} element={<Confirmation />} />
          <Route path={RoutePath.VOUCHER} element={<Voucher />} />
          <Route path={RoutePath.OTHERS} element={<Others />} />
          <Route path={RoutePath.ONBOARDING} element={<Onboarding />} />
          <Route path="*" element={<Navigate to={RoutePath.HOME} replace />} />
        </Routes>
      </div>
    </>
  )
}
