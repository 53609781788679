import { RoutePath } from '../../../../common/constants/route-path'
import { useBackButton } from '../../../../hooks/use-back-button'
import { useRouterEvent } from '../../../../hooks/use-router-event'
import { useGlobalContext } from '../../../../context/use-global-context'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTokenListener } from '../../../../hooks/use-token-listener'
import { useQuery } from '@tanstack/react-query'
import { AccountsResponse, PaymentResponse } from '../../../../models/api.interface'
import { executePaymentFromApi } from '../../../../services/payment-service/payment-service'
import { queryClient } from '../../../../common/config/query-client'
import { pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'

export const useConfirmation = () => {
  const navigate = useNavigate()

  const { softToken: softoken, timeLeft } = useTokenListener()

  const {
    globalState: { authEvent, rechargeAmount, selectedAccount, isOther },
    actions: { setTitle, showHeader }
  } = useGlobalContext()

  const { data: accountsData } = useQuery<AccountsResponse>({
    queryKey: ['getAccountsData', authEvent],
    enabled: false
  })

  const executePaymentExtraData = useMemo(() => {
    if (!accountsData || !selectedAccount) return undefined
    return {
      billCode: accountsData.bill.code,
      billCounterpartyCode: accountsData.bill.counterpartyCode,
      userCounterpartyCode: accountsData.userContraPartyCode,
      paymentOption: {
        accountNumber: selectedAccount.hash,
        accountType: selectedAccount.type,
        alias: accountsData.bill.paymentOption.alias,
        code: accountsData.bill.paymentOption.code,
        cypherAccount: selectedAccount.cipher,
        maskAccount: selectedAccount.mask,
        name: accountsData.bill.paymentOption.name,
        value: rechargeAmount
      },
      paymentType: accountsData.bill.paymentType,
      softoken,
      isOther
    }
  }, [accountsData, selectedAccount, softoken, rechargeAmount, isOther])

  const { isLoading, isError, refetch } = useQuery<PaymentResponse>({
    queryKey: ['executePayment', authEvent],
    enabled: false,
    queryFn: () => executePaymentFromApi(authEvent!, executePaymentExtraData!)
  })

  const details = useMemo(() => {
    if (!accountsData) return undefined
    return accountsData.bill.details
  }, [accountsData])

  const isConfirmButtonDisabled = useMemo(() => !softoken, [softoken])

  const isOtherTag = useMemo(() => (isOther ? '_OTHER' : ''), [isOther])

  const navigateToVoucher = async () => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.CONFIRMATION.GO_TO_VOUCHER}${isOtherTag}`
    })
    showHeader(false)
    const { status } = await refetch()
    if (status === 'error') showHeader(true)
    if (status === 'success') {
      window.scrollTo(0, 0)
      navigate(RoutePath.VOUCHER)
    }
  }

  const handleOnError = () => {
    queryClient.resetQueries({
      queryKey: ['executePayment']
    })
  }

  const goBack = () => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.CONFIRMATION.CANCEL_OPERATION}${isOtherTag}`
    })
    handleOnError()
    window.scrollTo(0, 0)
    navigate(RoutePath.RECHARGE)
  }

  const cancelOperation = () => {
    navigate(RoutePath.HOME)
  }

  useBackButton(goBack)
  useRouterEvent()

  useEffect(() => {
    setTitle('Confirmación')
  }, [])

  useEffect(() => {
    pushTrackEvent({
      eventName: `${TRA_VIEW_EVENTS.CONFIRMATION}${isOtherTag}`
    })
  }, [])

  return {
    details,
    rechargeAmount,
    selectedAccount,
    softToken: softoken,
    timeLeft,
    isConfirmButtonDisabled,
    navigateToVoucher,
    cancelOperation,
    isLoading,
    isError,
    handleOnError
  }
}

export default useConfirmation
