import './chip-button.scss'
import { PichinchaTypography } from '@pichincha/ds-react'
import classNames from 'classnames'
import { FC } from 'react'

interface ChipButtonProps {
  length: number
  index: number
  text: string
  isChecked: boolean
  images?: string[]
  symbol?: string
  onClickChip: () => void
}

const ChipButton: FC<ChipButtonProps> = ({
  index,
  isChecked,
  length,
  text,
  images,
  symbol,
  onClickChip
}) => {
  return (
    <div
      style={{ width: `${(100 / length).toString()}%` }}
      id={`chipButton${index}Id`}
      key={`chipButton${index}`}
      data-testid={`chipButton${index}TestId`}
      className={classNames(
        'chip-button',
        { 'chip-button--checked': isChecked && !images },
        { 'chip-button--has-image': images },
        { 'chip-button--has-image-checked': isChecked }
      )}
      tabIndex={-5}
      role="presentation"
      onClick={onClickChip}
    >
      {images && (
        <img
          className="chip-button__image"
          id="chipButtonImageId"
          data-testid={`chipButtonImage${index}TestId`}
          alt={text}
          src={images[index]}
        />
      )}
      <PichinchaTypography
        variant="prelo"
        weight="500"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
      >
        {symbol ? `${symbol} ${text}` : text}
      </PichinchaTypography>
    </div>
  )
}

export default ChipButton
