import { TRA_SERVICE_EVENTS } from '../../common/constants/tracking-events'
import { customHttpRequest } from '../../common/utils/utils'
import { AccountsResponse } from '../../models/api.interface'
import { AuthWebviewEvent } from '../../models/auth-webview-event'

const BASE_URL = process.env.REACT_APP_BASE_URL as string
const ACCOUNTS_URL = process.env.REACT_APP_DEBIT_ACCOUNTS_URL as string

export const getAccountsFromApi = async (authEvent: AuthWebviewEvent, counterpart?: string) => {
  const events = counterpart
    ? {
        success: TRA_SERVICE_EVENTS.ACCOUNTS_OTHERS.SUCCESS,
        error: TRA_SERVICE_EVENTS.ACCOUNTS_OTHERS.ERROR
      }
    : {
        success: TRA_SERVICE_EVENTS.ACCOUNTS_RECHARGE.SUCCESS,
        error: TRA_SERVICE_EVENTS.ACCOUNTS_RECHARGE.ERROR
      }

  return customHttpRequest<AccountsResponse>({
    authEvent,
    url: `${BASE_URL}${ACCOUNTS_URL}`,
    events,
    extraData: {
      documentType: 'cedula',
      counterpart: counterpart ?? authEvent.clientId
    }
  })
}
