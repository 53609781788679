import { createContext } from 'react'
import { GlobalState } from './global-state'
import { Actions } from './actions/actions'

export interface GlobalStateContext {
  globalState: GlobalState
  actions: Actions
}

export const GlobalContext = createContext<GlobalStateContext>({} as GlobalStateContext)
