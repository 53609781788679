import axios, { AxiosResponse } from 'axios'
import { logoutInApp } from '../../common/app-messages/app-messages'

const HTTP_UNAUTHORIZED = 401

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

export const onFullfilled = (response: AxiosResponse) => {
  const { status, data } = response

  const { errors = [] } = data || {}
  if (
    status === HTTP_UNAUTHORIZED ||
    (errors.length > 0 && errors[0].extensions?.status === HTTP_UNAUTHORIZED)
  ) {
    logoutInApp()
    return
  }

  return response
}

export const onRejected = (error: any) => {
  const {
    response: { status, data }
  } = error
  if (status === HTTP_UNAUTHORIZED) {
    logoutInApp()
    return Promise.reject(data)
  }
  return Promise.reject(data)
}

httpClient.interceptors.response.use(onFullfilled, onRejected)

export { httpClient }
