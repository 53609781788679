import { FC } from 'react'
import './detail-item.scss'
import { PichinchaTypography } from '@pichincha/ds-react'

interface DetailItemProps {
  label: string
  value: string | number
  isLabelBold?: boolean
  isValueBold?: boolean
}

const DetailItem: FC<DetailItemProps> = ({ label, value, isLabelBold, isValueBold }) => {
  return (
    <div className="detail-item">
      <PichinchaTypography
        variant="prelo"
        weight={isLabelBold ? '600' : '400'}
        size="16px"
        lineHeight="24px"
        color="bp-grayScale500"
      >
        {label}
      </PichinchaTypography>
      <PichinchaTypography
        variant="prelo"
        weight={isValueBold ? '600' : '400'}
        size="16px"
        lineHeight="24px"
        color="bp-grayScale500"
        align="right"
      >
        {value}
      </PichinchaTypography>
    </div>
  )
}

export default DetailItem
