import { httpClient } from '../../services/client/http-client'
import { HttpRequestParams } from '../../models/utils'
import { pushTrackEvent } from '../app-messages/app-messages'

export const isValid = (value: any) => value !== undefined && value !== null

export const numberWithDecimals = (number: any) => parseFloat(number).toFixed(2)

export const getNumberInThousandsFormat = (number: number) => {
  const numberComponents = numberWithDecimals(number).split('.')
  const integerPart = numberComponents[0]

  if (integerPart.length <= 3) {
    return numberComponents.join('.')
  }

  const formattedIntegerPart = integerPart
    .split('')
    .reverse()
    .map((digit, index) => {
      return index > 0 && index % 3 === 0 ? digit + ',' : digit
    })
    .reverse()
    .join('')

  return `${formattedIntegerPart}.${numberComponents[1] || ''}`
}

export const toMoneyFormat = (number = 0) => `$ ${getNumberInThousandsFormat(number)}`

export const removeMoneyFormat = (money: string) => parseFloat(money.replace(/[$,]/g, ''))

export const toHiddenMoneyFormat = (number = 0) => {
  if (number <= 0) {
    return '$ *.**'
  } else {
    const numberAsString = getNumberInThousandsFormat(number).replace(/\,/g, '')
    return '$ '.concat(numberAsString.replace(/\d/g, '*'))
  }
}

export const capitalizeText = (str: string) => {
  const words = str.split(' ')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords.join(' ')
}

export const formatDate = (inputDateString: string) => {
  if (!inputDateString) {
    return ''
  }
  const inputDate = new Date(inputDateString)

  const monthAbbreviations = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ]

  const day = inputDate.getUTCDate()
  const month = monthAbbreviations[inputDate.getUTCMonth()]
  const year = inputDate.getUTCFullYear()

  return `${day} ${month}.${year}`
}

export function shortenText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    text = text.slice(0, maxLength - 3) + '...'
  }
  return text
}

export const maskCardNumber = (cardNumber: string) => {
  return '**** **** **** ' + cardNumber.slice(-4)
}

export const validateDni = (dni: any) => {
  let total = 0
  const { length } = dni
  const lencheck = length - 1

  if (dni !== '' && length === 10) {
    for (let i = 0; i < lencheck; i += 1) {
      if (i % 2 === 0) {
        let aux = dni.charAt(i) * 2
        if (aux > 9) aux -= 9
        total += aux
      } else {
        total += parseInt(dni.charAt(i), 10)
      }
    }

    total = total % 10 ? 10 - (total % 10) : 0

    return dni.charAt(length - 1) === total.toString()
  }
  return false
}

export const consolidateCustomHttpRequest = async <T = any>({
  authEvent,
  url,
  events,
  extraData
}: HttpRequestParams) => {
  const { clientId: documentNumber, jwtToken } = authEvent

  const requestData = {
    documentNumber,
    ...extraData
  }

  const headers = {
    'x-document-number': documentNumber,
    Authorization: `Bearer ${jwtToken}`
  }

  try {
    const response = await httpClient.post<T>(url, requestData, { headers })

    pushTrackEvent({
      eventName: events.success
    })

    return response.data
  } catch (error) {
    pushTrackEvent({
      eventName: events.error
    })

    throw error
  }
}

export const customHttpRequest = async <T = any>({
  authEvent,
  url,
  events,
  extraData,
  extraHeaders
}: HttpRequestParams) => {
  const {
    cif,
    clientId: dni,
    clientIdType: dniType,
    jwtToken,
    channel,
    guid,
    session,
    device,
    ip
  } = authEvent

  const data = {
    cif,
    dni,
    dniType,
    ...extraData
  }

  const headers = {
    'x-device': device,
    'x-session': session,
    'x-device-ip': ip,
    'x-guid': guid,
    'x-document-number': dni,
    channel,
    Authorization: `Bearer ${jwtToken}`,
    ...extraHeaders
  }

  try {
    const response = await httpClient.post<T>(url, data, { headers })

    pushTrackEvent({
      eventName: events.success
    })

    return response.data
  } catch (error) {
    pushTrackEvent({
      eventName: events.error
    })
    throw error
  }
}
