import './security-feedback.scss'
import lottieAnimation from '../../../assets/animations/lockCheck.json'
import { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { PichinchaLottiePlayer, PichinchaSkeleton, PichinchaTypography } from '@pichincha/ds-react'

export const DEFAULT_MAX_TIME = 30000

export interface SecurityFeedbackProps {
  code: string | undefined
  animationTime: number | undefined
  description: string
}

export const SecurityFeedback = ({ animationTime, code, description }: SecurityFeedbackProps) => {
  const [percentage, setPercentage] = useState({
    value: 0,
    increment: 0
  })
  const [currentCode, setCurrentCode] = useState('')

  const getCodeAriaText = () => {
    return `Tu clave digital es: ${currentCode.split('').join(' ')}`
  }

  useEffect(() => {
    let intervalId: NodeJS.Timer
    let currentPercentage = 0
    if (code && animationTime) {
      if (code !== currentCode) {
        setCurrentCode(code)

        if (animationTime < DEFAULT_MAX_TIME) {
          const calculatedPercentage = (animationTime / DEFAULT_MAX_TIME) * 100
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            value: calculatedPercentage
          }))
          currentPercentage = calculatedPercentage
        } else {
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            value: 0
          }))
        }

        const incrementPercentage = (100 - currentPercentage) / (animationTime / 1000)

        setPercentage((prevPercentage) => ({
          ...prevPercentage,
          increment: incrementPercentage
        }))
        intervalId = setInterval(() => {
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            value: prevPercentage.value + prevPercentage.increment
          }))
        }, 1000)
      }
    }
    return () => {
      clearInterval(intervalId as NodeJS.Timeout)
    }
  }, [code])

  const renderTimer = () => {
    return (
      <div className="soft-token" aria-label={getCodeAriaText()} tabIndex={0}>
        <div className="soft-token__progress-bar" aria-hidden="true">
          <CircularProgressbar
            value={percentage.value}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: 'butt',
              trailColor: '#F4F6F9',
              pathColor: '#0F265C',
              pathTransition: percentage.value === 0 ? 'none' : 'stroke-dashoffset 0.5s ease 0s'
            })}
          />
        </div>
        <PichinchaTypography
          aria-hidden="true"
          variant="prelo"
          weight="400"
          size="14px"
          lineHeight="20px"
          category="text"
          color="bp-grayScale400"
        >
          {currentCode}
        </PichinchaTypography>
      </div>
    )
  }

  return (
    <div className="security-feedback" role="presentation">
      <div className="security-feedback__header" role="presentation">
        <div className="token-timer__lottie" aria-hidden="true">
          <div className="token-timer__lottie-bg1">
            <div className="token-timer__lottie-bg2"></div>
          </div>
          <div className="token-timer__animation" aria-hidden="true">
            <PichinchaLottiePlayer
              lottie={JSON.stringify(lottieAnimation)}
              loop={true}
              width="135px"
              height="135px"
              customCss="animation"
            />
          </div>
        </div>
        {currentCode ? (
          renderTimer()
        ) : (
          <PichinchaSkeleton variant="title" size="medium" animation />
        )}
      </div>
      <PichinchaTypography
        variant="prelo"
        weight="400"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
        align="center"
      >
        {description}
      </PichinchaTypography>
    </div>
  )
}
