import './home-skeleton.scss'
import { PichinchaSkeleton } from '@pichincha/ds-react'

const HomeSkeleton = () => {
  return (
    <>
      <div className="home-skeleton" data-testid="home-skeleton">
        <div className="home-skeleton__content">
          <PichinchaSkeleton
            className="home-skeleton__content__title"
            variant="title"
            color="solidLight"
            size="medium"
            animation
          />
          <PichinchaSkeleton
            className="home-skeleton__content__subtitle"
            variant="title"
            color="solidLight"
            size="medium"
            animation
          />
          <div className="home-skeleton__content__card">
            <div className="home-skeleton__content__card__info">
              <PichinchaSkeleton
                className="home-skeleton__content__card__info__name"
                variant="title"
                color="solidLight"
                size="large"
                animation
              />
              <PichinchaSkeleton
                className="home-skeleton__content__card__info__number"
                variant="title"
                color="solidLight"
                size="medium"
                animation
              />
              <PichinchaSkeleton
                className="home-skeleton__content__card__info__balance"
                variant="title"
                color="solidLight"
                size="medium"
                animation
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeSkeleton
