import { pushTrackEvent } from '../../common/app-messages/app-messages'
import { TRA_SERVICE_EVENTS } from '../../common/constants/tracking-events'
import { MaxAndMinValuesResponse } from '../../models/api.interface'
import { AuthWebviewEvent } from '../../models/auth-webview-event'
import { httpClient } from '../client/http-client'

const BASE_URL = process.env.REACT_APP_BASE_URL as string
const MAX_MIN_URL = process.env.REACT_APP_MAX_MIN_URL as string

export const getMaxAndMinValues = async (authEvent: AuthWebviewEvent) => {
  const { clientId: documentNumber, jwtToken } = authEvent

  const headers = {
    'x-document-number': documentNumber,
    Authorization: `Bearer ${jwtToken}`
  }

  try {
    const { data } = await httpClient.get<MaxAndMinValuesResponse>(`${BASE_URL}${MAX_MIN_URL}`, {
      headers
    })

    pushTrackEvent({
      eventName: TRA_SERVICE_EVENTS.MAX_AND_MIN_VALUES.SUCCESS
    })

    return data
  } catch (error) {
    pushTrackEvent({
      eventName: TRA_SERVICE_EVENTS.MAX_AND_MIN_VALUES.ERROR
    })

    throw error
  }
}
