import './others-product-skeleton.scss'
import { PichinchaSkeleton } from '@pichincha/ds-react'

const OthersProductSkeleton = () => {
  return (
    <div data-testid={'othersProductSkeletonTestId'} className="others-product-skeleton">
      <PichinchaSkeleton
        className="others-product-skeleton__title"
        variant="title"
        color="solidLight"
        size="medium"
        animation
      />
      <PichinchaSkeleton
        className="others-product-skeleton__name"
        variant="title"
        color="solidLight"
        size="small"
        animation
      />
      <PichinchaSkeleton
        className="others-product-skeleton__card-title"
        variant="title"
        color="solidLight"
        size="medium"
        animation
      />
      <PichinchaSkeleton
        className="others-product-skeleton__card-name"
        variant="title"
        color="solidLight"
        size="small"
        animation
      />
    </div>
  )
}

export default OthersProductSkeleton
