import './confirmation.scss'

import RechargeDetail from '../../molecules/recharge-detail/recharge-detail'
import { SecurityFeedback } from '../../molecules/security-feedback/security-feedback'

import {
  PichinchaButton,
  PichinchaDivider,
  PichinchaLoadingScreen,
  PichinchaTypography
} from '@pichincha/ds-react'
import { ErrorPage } from '../error-page/error-page'
import useConfirmation from './use-confirmation/use-confirmation'

const Confirmation = () => {
  const {
    details,
    selectedAccount,
    rechargeAmount,
    isConfirmButtonDisabled,
    softToken,
    timeLeft,
    navigateToVoucher,
    cancelOperation,
    isLoading,
    isError,
    handleOnError
  } = useConfirmation()

  if (isLoading)
    return <PichinchaLoadingScreen description="Recargando tarjeta" isDesktop={false} />
  if (isError) return <ErrorPage fetchDataAgain={handleOnError} />
  return (
    <div className="confirmation-page" data-testid="confirmationPageTestId">
      <div className="confirmation-page__body">
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="18px"
          lineHeight="24px"
          category="text"
          color="bp-primary-blue500"
          align="center"
        >
          Confirma los datos de tu recarga
        </PichinchaTypography>
        <SecurityFeedback
          animationTime={timeLeft}
          code={softToken}
          description="Este código único nos permite verificar que la recarga se hace de forma segura desde tu celular."
        />
        {details && (
          <RechargeDetail
            rechargeAmount={rechargeAmount}
            productName={'Visa Metro de Quito'}
            productNumber={details.reference}
            accountName={selectedAccount?.alias ?? ''}
            accountNumber={selectedAccount?.mask ?? ''}
          />
        )}
      </div>
      <div className="confirmation-page__footer">
        <PichinchaDivider variant="solid" style={{ padding: 0 }} />
        <div className="confirmation-page__footer__button">
          <PichinchaButton
            label={'Continuar'}
            variant="primary"
            size="large"
            customWidth="100%"
            disabled={isConfirmButtonDisabled}
            onClick={navigateToVoucher}
          />
          <PichinchaButton
            label={'Cancelar'}
            variant="secondary"
            size="large"
            customWidth="100%"
            onClick={cancelOperation}
          />
        </div>
      </div>
    </div>
  )
}

export default Confirmation
