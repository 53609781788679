import { FileOptions } from '../../models/file-options'
import { WebviewMessages } from '../../models/webview-messages'

export interface AppMessage {
  type: string
  customData?: object
  detail?: object
}

const BW_ORIGIN = process.env.REACT_APP_WEB_BANKING_URL || '*'

const isReactNativeWebview = () =>
  window.ReactNativeWebView !== undefined && window.ReactNativeWebView.postMessage !== undefined

const send = (message: AppMessage) => {
  if (isReactNativeWebview()) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  } else {
    window.parent.window.postMessage(JSON.stringify(message), BW_ORIGIN)
  }
}

const goBackAppHome = () => {
  const message = { type: WebviewMessages.GO_BACK_HOME }
  send(message)
}

const navigateToAppScreen = (screenName: string, appScreenParams?: object) => {
  const message = {
    type: WebviewMessages.NAVIGATE_TO_SCREEN,
    appScreen: screenName,
    appScreenParams: appScreenParams
  }
  send(message)
}

const goToAppScreen = (screenName: string, appScreenParams?: object) => {
  const message = {
    type: WebviewMessages.OPEN_SCREEN,
    appScreen: screenName,
    appScreenParams: appScreenParams
  }
  send(message)
}

const goToUrl = (url: string) => {
  const message = { type: WebviewMessages.OPEN_BROWSER, url }
  send(message)
}

const logoutInApp = () => {
  const message = { type: WebviewMessages.LOGOUT }
  send(message)
}

const reloadProductsInApp = () => {
  const message = { type: WebviewMessages.RELOAD_PRODUCTS }
  send(message)
}

const validateOnboardingInApp = (routeName: string, appScreenParams?: object) => {
  const message = {
    type: WebviewMessages.VALIDATE_ONBOARDING_BEFORE_TRANSACTING,
    routeName,
    appScreenParams
  }
  send(message)
}

const shareInApp = (file: string, fileOptions: FileOptions | null) => {
  const message = {
    type: WebviewMessages.NATIVE_SHARE,
    shareFile: file,
    shareOptions: fileOptions
  }
  send(message)
}

const downloadInApp = (file: string, contentType: string, fileName: string) => {
  const fileInfo = {
    content: file,
    contentType: contentType,
    name: fileName
  }
  const message = { type: WebviewMessages.DOWNLOAD_FILE, fileInfo }
  send(message)
}
const updateIsVisibleAmountInAppLocalStorage = (localStorageValue: boolean) => {
  const message = {
    type: WebviewMessages.UPDATE_IS_VISIBLE_AMOUNT_IN_LOCAL_STORAGE,
    localStorageValue
  }
  send(message)
}

const pushTrackEvent = (customData: { eventName: string }) => {
  send({ type: WebviewMessages.PUSH_TRACK_EVENT, customData })
}

export {
  isReactNativeWebview,
  send,
  goBackAppHome,
  navigateToAppScreen,
  goToAppScreen,
  goToUrl,
  logoutInApp,
  reloadProductsInApp,
  validateOnboardingInApp,
  shareInApp,
  downloadInApp,
  updateIsVisibleAmountInAppLocalStorage,
  pushTrackEvent
}
