import './recharge.scss'
import {
  PichinchaDivider,
  PichinchaSelectorAccount,
  PichinchaAlerts,
  PichinchaButton,
  PichinchaLoadingScreen,
  PichinchaIcon,
  PichinchaTypography
} from '@pichincha/ds-react'
import NumericInput from '../../molecules/numeric-input/numeric-input'
import ChipButtons from '../../molecules/chip-buttons/chip-buttons'
import useRecharge from './use-recharge/use-recharge'
import { capitalizeText, toMoneyFormat } from '../../../common/utils/utils'
import { ErrorPage } from '../error-page/error-page'
import { AMOUNTS } from '../../../common/constants/amounts'

const Recharge = () => {
  const {
    accountToDisplay,
    details,
    handleBackButton,
    handleInputChange,
    isErrorOnInput,
    isErrorAccounts,
    isLoadingAccounts,
    navigateToAccounts,
    navigateToConfirmation,
    rechargeAmount,
    inputError,
    isContinueButtonDisabled,
    limitValues
  } = useRecharge()

  if (isLoadingAccounts) {
    return (
      <PichinchaLoadingScreen
        description="Espera un  momento"
        data-testid="recharge-loading-testid"
      />
    )
  }
  if (isErrorAccounts || !details) {
    return <ErrorPage fetchDataAgain={handleBackButton} />
  }
  return (
    <div className="recharge-page" data-testid="rechargePageTestId">
      <div className="recharge-page__body">
        <NumericInput
          prefix="$ "
          decimalScale={2}
          decimalSeparator="."
          thousandSeparator=","
          fixedDecimalScale={false}
          allowNegative={false}
          autofocus={true}
          inputMode="decimal"
          valueIsNumericString={true}
          inputValue={rechargeAmount || ''}
          placeholder="$ 0,00"
          type="text"
          isError={isErrorOnInput}
          errorHelper={
            inputError === 'exceedsMinLimit'
              ? `El valor debe ser mayor o igual a ${toMoneyFormat(limitValues?.minValueRecharge)}`
              : `El monto debe ser igual o menor a ${toMoneyFormat(limitValues?.maxValueRecharge)}`
          }
          helperText={`Podrás recargar desde ${toMoneyFormat(limitValues?.minValueRecharge)}`}
          onChange={handleInputChange}
        />
        <div className="recharge-page__body__amount-selector">
          <ChipButtons
            id="chipButtonsTestId"
            data={AMOUNTS}
            symbol="$"
            onClick={(value) => {
              handleInputChange(value)
            }}
          />
        </div>
        <div className="recharge-page__body__account-selector">
          <div className="recharge-page__body__account-selector__selected">
            <PichinchaSelectorAccount
              label="Desde"
              labelTitleLeft={capitalizeText(accountToDisplay?.alias || '')}
              labelDescLeft={`Nro. ${accountToDisplay?.mask}`}
              labelTitleRight={accountToDisplay?.balance}
              labelDescRight="Saldo disponible"
              iconRight="chevron_right"
              iconColorRight="bp-grayScale300"
              onClickEvent={navigateToAccounts}
            />
            {inputError === 'noBalance' && (
              <div className="recharge-page__body__account-selector__selected__error">
                <div className="recharge-page__body__account-selector__selected__error__input">
                  <PichinchaIcon size="16px" cssColorValue="#C70404" type="filled">
                    info
                  </PichinchaIcon>
                </div>
                <PichinchaTypography
                  variant="prelo"
                  weight="500"
                  size="12px"
                  lineHeight="16px"
                  color="bp-alert-error700"
                >
                  Fondos insuficientes para realizar esta transacción.
                </PichinchaTypography>
              </div>
            )}
          </div>
          <PichinchaSelectorAccount
            label="Para"
            labelTitleLeft={capitalizeText(details?.customerName || '')}
            labelDescLeft={`Nro. ${details?.reference}`}
            aria-label="test"
            readonly
          />
        </div>
        <div>
          <PichinchaAlerts
            type="information"
            description="Esta transacción no tiene costo."
            allowClose={false}
            hasIcon
            show
          />
        </div>
      </div>
      <div className="recharge-page__footer">
        <PichinchaDivider variant="solid" style={{ padding: 0 }} />
        <div className="recharge-page__footer__button">
          <PichinchaButton
            label="Continuar"
            variant="primary"
            size="large"
            customWidth="100%"
            onClick={navigateToConfirmation}
            disabled={isContinueButtonDisabled}
          />
        </div>
      </div>
    </div>
  )
}

export default Recharge
