import './card.scss'
import { PichinchaTypography, PichinchaIcon } from '@pichincha/ds-react'
import metroIcon from '../../../assets/metroIcon.svg'
import metroLogo from '../../../assets/metroLogo.svg'
import cardImage from '../../../assets/cardImage.svg'
import { toMoneyFormat, toHiddenMoneyFormat } from '../../../common/utils/utils'
import { useState } from 'react'
import { pushTrackEvent } from '../../../common/app-messages/app-messages'
import { TRA_TRACKING_EVENTS } from '../../../common/constants/tracking-events'

export interface CardProps {
  id: string
  name: string | undefined
  cardNumber: string | ''
  balance: number | undefined
  isBalanceVisible: boolean
  onClick: () => void
}

function Card({ id, name, cardNumber, balance, isBalanceVisible, onClick }: CardProps) {
  const [balanceVisible, setBalanceVisible] = useState(isBalanceVisible)

  const handleBalanceVisibility = (event: React.MouseEvent | React.TouchEvent) => {
    pushTrackEvent({
      eventName: balanceVisible
        ? TRA_TRACKING_EVENTS.HOME.HIDE_AMOUNT
        : TRA_TRACKING_EVENTS.HOME.SHOW_AMOUNT
    })
    setBalanceVisible(!balanceVisible)
    event.stopPropagation()
  }

  return (
    <div
      className="card-container"
      data-testid={id}
      onClick={onClick}
      style={{
        backgroundImage: `url(${cardImage})`
      }}
    >
      <div className="card-container__content">
        <div className="card-container__content__header">
          <div className="card-container__content__header__identification">
            <PichinchaTypography
              variant="prelo"
              weight="600"
              size="18px"
              lineHeight="24px"
              category="text"
              color="bp-grayScaleWhite"
              align="left"
            >
              {name}
            </PichinchaTypography>
            <PichinchaTypography
              variant="prelo"
              weight="400"
              size="14px"
              lineHeight="20px"
              category="text"
              color="bp-grayScaleWhite"
              align="left"
            >
              Nro. {cardNumber}
            </PichinchaTypography>
          </div>
          <div className="card-container__content__header__logo">
            <img src={metroIcon} alt="Icon Metro" />
            <img src={metroLogo} alt="Logo Metro" />
          </div>
        </div>
        <div className="card-container__content__footer">
          <div className="card-container__content__footer__information">
            <PichinchaTypography
              variant="prelo"
              weight="500"
              size="12px"
              lineHeight="16px"
              category="text"
              color="bp-grayScaleWhite"
              align="left"
            >
              Saldo disponible
            </PichinchaTypography>
            <div className="card-container__content__footer__information__balance" data-hj-suppress>
              <PichinchaTypography
                variant="prelo"
                weight="600"
                size="28px"
                lineHeight="32px"
                category="text"
                color="bp-grayScaleWhite"
                align="left"
              >
                {balanceVisible ? toMoneyFormat(balance) : toHiddenMoneyFormat(balance)}
              </PichinchaTypography>
              <PichinchaIcon
                type="filled"
                size="24px"
                cssColorValue="#FFFFFF"
                onClick={handleBalanceVisibility}
                className="visibility-icon"
              >
                {balanceVisible ? 'visibility' : 'visibility_off'}
              </PichinchaIcon>
            </div>
          </div>
          <div className="card-container__content__footer__button">
            <PichinchaIcon size="24px" cssColorValue="#FFFFFF">
              east
            </PichinchaIcon>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Card }
