import './home.scss'
import {
  PichinchaTypography,
  PichinchaModal,
  PichinchaButton,
  PichinchaEmptyState
} from '@pichincha/ds-react'
import useHome from './use-home/use-home'
import { Card } from '../../molecules/card/card'
import HomeSkeleton from '../../templates/home-skeleton/home-skeleton'
import ProductInformation from '../../molecules/product-information/product-information'
import { strings } from '../../../common/constants/strings'
import { capitalizeText, shortenText } from '../../../common/utils/utils'
import { EMPTY_STATE_TYPES } from '@pichincha/ds-core'

export const Home = () => {
  const {
    card,
    cards,
    handleCloseModal,
    isErrorConsolidate,
    isLoadingConsolidate,
    navigateToOthers,
    navigateToRecharge,
    openModal,
    retryGetConsolidate
  } = useHome()

  const renderCardSelector = () => {
    if (isErrorConsolidate) {
      return (
        <PichinchaEmptyState
          data-testid="error-emptyStateTestId"
          descriptionText="Tuvimos inconvenientes al cargar tus tarjetas. Inténtalo de nuevo o recarga seleccionando 'Recargar otra tarjeta'."
          type={'gray_background' as EMPTY_STATE_TYPES}
          iconName="refresh"
          iconProps={{ size: '24px' }}
          style={{ paddingBottom: '16px' }}
          linkButtonProps={{ label: 'Volver a cargar', onClickEvent: retryGetConsolidate }}
        />
      )
    }
    if (card && card.cardType === 'UIO') {
      return (
        <Card
          id="cardTestId"
          name={shortenText(capitalizeText(card.customerName), 22)}
          cardNumber={card.cardNumber}
          balance={card.balance}
          isBalanceVisible={false}
          onClick={navigateToRecharge}
        />
      )
    }
    if (cards.length === 0) {
      return (
        <PichinchaEmptyState
          data-testid="emptyStateTestId"
          titleText="Aún no tienes tu tarjeta ciudad "
          descriptionText="Puedes solicitarla en cualquier estación del Metro de Quito"
          type={'card' as EMPTY_STATE_TYPES}
          iconName="credit_card"
          iconProps={{ size: '24px' }}
          style={{ paddingBottom: '16px' }}
          linkButtonProps={{ label: '' }}
        />
      )
    }
  }

  if (isLoadingConsolidate) {
    return <HomeSkeleton />
  }
  return (
    <>
      <div className="home" data-testid="home-testid">
        <div className="home__content">
          <PichinchaTypography
            variant="prelo"
            weight="400"
            size="16px"
            lineHeight="24px"
            category="text"
            color="bp-grayScale500"
            align="left"
            style={{ paddingBottom: '24px' }}
          >
            ¿Qué deseas recargar?
          </PichinchaTypography>
          <PichinchaTypography
            variant="prelo"
            weight="600"
            size="16px"
            lineHeight="24px"
            category="text"
            color="bp-grayScale500"
            align="left"
            style={{ paddingBottom: '8px' }}
          >
            Mi tarjeta
          </PichinchaTypography>
          <div className="home__content__selector">{renderCardSelector()}</div>
          <PichinchaTypography
            variant="prelo"
            weight="500"
            size="14px"
            lineHeight="20px"
            category="text"
            color="bp-alert-information500"
            align="left"
            decoration="underline"
            onClick={handleCloseModal}
          >
            ¿Qué es la Tarjeta Ciudad?
          </PichinchaTypography>
        </div>
        <div className="home__footer">
          <div className="home__footer__button">
            <PichinchaButton
              label="Recargar otra tarjeta"
              variant="information-primary"
              size="large"
              customBorderRadius="40px"
              iconName="attach_money"
              onClick={navigateToOthers}
            />
          </div>
        </div>
      </div>
      <PichinchaModal
        borderHeader
        fullSizeButtons
        showCloseButton
        onCloseEvent={handleCloseModal}
        open={openModal}
      >
        <div className="modal-content">
          <ProductInformation productTexts={strings.cardModalInformation} />
        </div>
        <div slot="footer" className="modal-content__footer">
          <PichinchaButton
            onClickEvent={handleCloseModal}
            customWidth="100%"
            label="Entendido"
            variant="primary"
            size="large"
          />
        </div>
      </PichinchaModal>
    </>
  )
}

export default Home
