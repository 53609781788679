import { useGlobalContext } from '../../../../context/use-global-context'
import { useEffect, useMemo, useState } from 'react'
import { useBackButton } from '../../../../hooks/use-back-button'
import { useRouterEvent } from '../../../../hooks/use-router-event'
import { RoutePath } from '../../../../common/constants/route-path'
import { useNavigate } from 'react-router-dom'
import { validateDni } from '../../../../common/utils/utils'
import { ERRORS } from '../../../../common/constants/errors'
import { useQuery } from '@tanstack/react-query'
import { getAccountsFromApi } from '../../../../services/accounts-service/accounts-service'
import { AccountsResponse, ErrorState } from '../../../../models/api.interface'
import { queryClient } from '../../../../common/config/query-client'
import { emptyAccountsResponse } from '../../../../common/constants/mocks'
import { pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'

const useOthers = () => {
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isError, setIsError] = useState(false)

  const navigate = useNavigate()

  const {
    globalState: { authEvent },
    actions: { setTitle }
  } = useGlobalContext()

  const {
    data,
    isLoading,
    isError: isErrorOthersData,
    error,
    refetch
  } = useQuery<AccountsResponse, ErrorState>({
    queryKey: ['getAccountsData', authEvent],
    queryFn: () => getAccountsFromApi(authEvent!, inputValue),
    enabled: false
  })

  const accountsData = useMemo(() => {
    if (!data) return emptyAccountsResponse
    return data
  }, [data])

  const getOthersCustomerData = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.OTHERS.TAP_SEARCH_OTHER
    })
    refetch()
  }

  const handleNavigateToHome = () => {
    window.scrollTo(0, 0)
    navigate(RoutePath.HOME)
    queryClient.resetQueries({
      queryKey: ['getAccountsData']
    })
  }

  const navigateToRecharge = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.OTHERS.GO_TO_RECHARGE
    })
    window.scrollTo(0, 0)
    navigate(RoutePath.RECHARGE)
  }

  const handleInputChange = (value: any) => {
    setInputValue(value)
    setIsError(!validateDni(value))
  }

  const resetOthersScreen = (isOnButton = false) => {
    if (isOnButton)
      pushTrackEvent({
        eventName: TRA_TRACKING_EVENTS.OTHERS.TRY_AGAIN_FETCH
      })
    queryClient.resetQueries({
      queryKey: ['getAccountsData']
    })
    setTitle('Recargar otra tarjeta')
  }

  const closeErrorModal = () => setShowErrorModal(false)

  useBackButton(handleNavigateToHome)
  useRouterEvent()

  useEffect(() => {
    resetOthersScreen()
  }, [])

  useEffect(() => {
    if (isErrorOthersData && error.code === ERRORS.ERROR_412) {
      pushTrackEvent({
        eventName: TRA_TRACKING_EVENTS.OTHERS.SHOW_ERROR_MODAL
      })
      resetOthersScreen()
      setShowErrorModal(true)
    }
  }, [isErrorOthersData, error])

  useEffect(() => {
    pushTrackEvent({
      eventName: TRA_VIEW_EVENTS.OTHERS
    })
  }, [])

  return {
    accountsData,
    inputValue,
    isError,
    getOthersCustomerData,
    handleInputChange,
    handleNavigateToHome,
    navigateToRecharge,
    resetOthersScreen,
    showErrorModal,
    closeErrorModal,
    isLoading,
    isErrorOthersData,
    error
  }
}

export default useOthers
