/*
  SOURCE_ -> Indica desde donde se origina el evento. En nuestro caso BM (Banca Movil). 2 MAX
  PRODUCTO/FLUJO_ -> Indica el producto que pertenece el evento. En nuestro caso TRA (Transportes) 5 max
  SEGMENTO/SUBPRODUCTO_ -> Webview ??? Posicion Consolidada ??? 4 MAX
  PANTALLA/SITE_ -> Da una breve descripcion de la ubicacion dentro del flujo de la pantalla. 11 MAX
  ELEMENTOS_ -> Indica que origina el evento. Boton, Link, Checkbox, etc. 4 MAX
  ACCION_ -> Indica la accion que se realiza. Click o View. 3 MAX
  AUXILIAR_ -> Da informacion adicional que se desee incluir. Normalmente si es adicional 4-5 MAX

  BM_TRA_RECH_PANTALLA_ELEMENTOS_ACCION_AUX
*/

export const TRA_VIEW_EVENTS = {
  ONBOARDING: 'BM_TRA_RECH_Onb_Scrn_Viw',
  HOME: 'BM_TRA_RECH_Home_Scrn_Viw',
  OTHERS: 'BM_TRA_RECH_Others_Scrn_Viw',
  RECHARGE: 'BM_TRA_RECH_Recharge_Scrn_Viw', // AUX FOR isOthers -> _OTHER
  DEBIT_ACCOUNTS: 'BM_TRA_RECH_DebitAcc_Scrn_Viw', // AUX FOR isOthers -> _OTHER
  CONFIRMATION: 'BM_TRA_RECH_Confirm_Scrn_Viw', // AUX FOR isOthers -> _OTHER
  VOUCHER: 'BM_TRA_RECH_Voucher_Scrn_Viw' // AUX FOR isOthers -> _OTHER
}

export const TRA_TRACKING_EVENTS = {
  ONBOARDING: {
    TAP_CONTINUE: 'BM_TRA_RECH_Onb_Btn_Tap'
  },
  HOME: {
    TAP_RECHARGE: 'BM_TRA_RECH_Home_Card_Tap',
    TAP_OTHERS: 'BM_TRA_RECH_Home_Btn_Tap',
    OPEN_MODAL: 'BM_TRA_RECH_Home_Modl_Opn',
    CLOSE_MODAL: 'BM_TRA_RECH_Home_Modl_Cls',
    SHOW_AMOUNT: 'BM_TRA_RECH_Home_Mony_Shw',
    HIDE_AMOUNT: 'BM_TRA_RECH_Home_Mony_Hid'
  },
  OTHERS: {
    TRY_AGAIN_FETCH: 'BM_TRA_RECH_Others_Err_Tap',
    TAP_SEARCH_OTHER: 'BM_TRA_RECH_Others_Btn_Tap',
    GO_TO_RECHARGE: 'BM_TRA_RECH_Others_Nav_Tap',
    SHOW_ERROR_MODAL: 'BM_TRA_RECH_Others_Mdal_Shw'
  },
  RECHARGE: {
    TAP_CHIP: 'BM_TRA_RECH_Recharge_Chip_Tap', // AUX FOR TEXT
    GO_TO_ACCOUNTS: 'BM_TRA_RECH_Recharge_Acnt_Tap', // AUX FOR isOthers -> _OTHER
    GO_TO_CONFIRMATION: 'BM_TRA_RECH_Recharge_Btn_Tap' // AUX FOR isOthers -> _OTHER
  },
  DEBIT_ACCOUNTS: {
    SELECT_ACCOUNT: 'BM_TRA_RECH_DebitAcc_Acnt_Tap' // AUX FOR isOthers -> _OTHER
  },
  CONFIRMATION: {
    GO_TO_VOUCHER: 'BM_TRA_RECH_Confirm_Btn_Tap', // AUX FOR isOthers -> _OTHER
    CANCEL_OPERATION: 'BM_TRA_RECH_Confirm_Cncl_Tap' // AUX FOR isOthers -> _OTHER
  },
  VOUCHER: {
    DOWNLOAD_VOUCHER: 'BM_TRA_RECH_Voucher_Btn_Tap', // AUX FOR isOthers -> _OTHER
    GO_BACK_WEBVIEW_HOME: 'BM_TRA_RECH_Voucher_Card_Tap', // AUX FOR isOthers -> _OTHER
    GO_BACK_APP_HOME: 'BM_TRA_RECH_Voucher_Nav_Tap' // AUX FOR isOthers -> _OTHER
  }
}

export const TRA_SERVICE_EVENTS = {
  CUSTOMER: {
    SUCCESS: 'BM_TRA_RECH_Home_Serv_OK',
    ERROR: 'BM_TRA_RECH_Home_Serv_ERR'
  },
  MAX_AND_MIN_VALUES: {
    SUCCESS: 'BM_TRA_RECH_Home_Serv_OK_MxMn',
    ERROR: 'BM_TRA_RECH_Home_Serv_ERR_MxMn'
  },
  ACCOUNTS_RECHARGE: {
    SUCCESS: 'BM_TRA_RECH_Recharge_Serv_OK',
    ERROR: 'BM_TRA_RECH_Recharge_Serv_ERR'
  },
  ACCOUNTS_OTHERS: {
    SUCCESS: 'BM_TRA_RECH_Others_Serv_OK',
    ERROR: 'BM_TRA_RECH_Others_Serv_ERR'
  },
  PAYMENT: {
    SUCCESS: 'BM_TRA_RECH_Confirm_Serv_OK',
    ERROR: 'BM_TRA_RECH_Confirm_Serv_ERR'
  },
  VIEW_ONBOARDING: {
    SUCCESS: 'BM_TRA_RECH_Onb_Serv_OK_View',
    ERROR: 'BM_TRA_RECH_Onb_Serv_ERR_View'
  },
  UPDATE_ONBOARDING: {
    SUCCESS: 'BM_TRA_RECH_Onb_Serv_OK',
    ERROR: 'BM_TRA_RECH_Onb_Serv_ERR'
  }
}
