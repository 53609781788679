import { useGlobalContext } from '../../../../context/use-global-context'
import { useEffect, useMemo, useState } from 'react'
import { useBackButton } from '../../../../hooks/use-back-button'
import { useRouterEvent } from '../../../../hooks/use-router-event'
import { RoutePath } from '../../../../common/constants/route-path'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { AccountsResponse, MaxAndMinValuesResponse } from '../../../../models/api.interface'
import { removeMoneyFormat } from '../../../../common/utils/utils'
import { pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'
import { getAccountsFromApi } from '../../../../services/accounts-service/accounts-service'
import { getMaxAndMinValues } from '../../../../services/catalog-service/catalog-service'

type InputErrorType = '' | 'exceedsMaxLimit' | 'exceedsMinLimit' | 'noBalance'

const useRecharge = () => {
  const [inputError, setInputError] = useState<InputErrorType>('')
  const [isErrorOnInput, setIsErrorOnInput] = useState(false)

  const navigate = useNavigate()

  const {
    globalState: { authEvent, selectedAccount, rechargeAmount, isOther },
    actions: { setTitle, setSelectedAccount, setRechargeAmount }
  } = useGlobalContext()

  const {
    data: accountsData,
    isError: isErrorAccounts,
    isLoading,
    isPending
  } = useQuery<AccountsResponse>({
    queryKey: ['getAccountsData', authEvent],
    queryFn: () => getAccountsFromApi(authEvent!),
    enabled: !!authEvent && !isOther
  })

  const {
    data: limitValues,
    isError: isErrorOnLimitValues,
    isLoading: isLoadingLimitValues
  } = useQuery<MaxAndMinValuesResponse>({
    queryKey: ['getMaxAndMinValues', authEvent],
    queryFn: () => getMaxAndMinValues(authEvent!),
    enabled: !!authEvent
  })

  const isOtherTag = useMemo(() => (isOther ? '_OTHER' : ''), [isOther])

  const accounts = useMemo(() => {
    if (!accountsData) return []
    return accountsData.accounts
  }, [accountsData])

  const details = useMemo(() => {
    if (!accountsData) return undefined
    return accountsData.bill.details
  }, [accountsData])

  const accountToDisplay = useMemo(() => {
    if (selectedAccount) return selectedAccount

    const favoriteAccountIndex = accounts.findIndex((account) => account.favorite)

    if (favoriteAccountIndex !== -1) return accounts[favoriteAccountIndex]

    return accounts[0]
  }, [selectedAccount, accounts])

  const isContinueButtonDisabled = useMemo(() => {
    if (
      isErrorOnInput ||
      !!inputError ||
      rechargeAmount.trim() === '' ||
      !selectedAccount ||
      !limitValues
    )
      return true

    const floatAmount = parseFloat(rechargeAmount)

    if (floatAmount < limitValues.minValueRecharge || floatAmount > limitValues.maxValueRecharge)
      return true

    return floatAmount > removeMoneyFormat(selectedAccount?.balance ?? '0') || floatAmount <= 0
  }, [isErrorOnInput, inputError, rechargeAmount, selectedAccount, limitValues])

  const handleBackButton = () => {
    const routeToNavigate = isOther ? RoutePath.OTHERS : RoutePath.HOME
    window.scrollTo(0, 0)
    navigate(routeToNavigate)
  }

  const navigateToConfirmation = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.RECHARGE.GO_TO_CONFIRMATION
    })
    window.scrollTo(0, 0)
    navigate(RoutePath.CONFIRMATION)
  }

  const navigateToAccounts = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.RECHARGE.GO_TO_ACCOUNTS
    })
    window.scrollTo(0, 0)
    navigate(RoutePath.ACCOUNTS)
  }

  const handleBlur = (amount = rechargeAmount, ranges = limitValues) => {
    if (!amount) return setIsErrorOnInput(false)

    const floatAmount = parseFloat(amount)

    if (ranges && floatAmount < ranges.minValueRecharge) {
      setInputError('exceedsMinLimit')
      setIsErrorOnInput(true)
      return
    }

    if (ranges && floatAmount > ranges.maxValueRecharge) {
      setInputError('exceedsMaxLimit')
      setIsErrorOnInput(true)
      return
    }

    setIsErrorOnInput(false)

    if (floatAmount > removeMoneyFormat(selectedAccount?.balance ?? '0'))
      return setInputError('noBalance')
    setInputError('')
  }

  const handleInputChange = (amount: string) => {
    handleBlur(amount)
    setRechargeAmount(amount)
  }

  useBackButton(handleBackButton)
  useRouterEvent()

  useEffect(() => {
    setTitle('Recargar mi tarjeta')
  }, [])

  useEffect(() => {
    if (accounts) {
      setSelectedAccount(accountToDisplay)
    }
  }, [accounts])

  useEffect(() => {
    pushTrackEvent({
      eventName: `${TRA_VIEW_EVENTS.RECHARGE}${isOtherTag}`
    })
  }, [])

  return {
    accounts,
    details,
    accountToDisplay,
    selectedAccount,
    rechargeAmount,
    isErrorOnInput,
    handleInputChange,
    handleBlur,
    handleBackButton,
    navigateToConfirmation,
    navigateToAccounts,
    isLoadingAccounts: isLoading || isPending || isLoadingLimitValues,
    isErrorAccounts: isErrorAccounts || isErrorOnLimitValues,
    inputError,
    isContinueButtonDisabled,
    limitValues
  }
}

export default useRecharge
