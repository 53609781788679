import './numeric-input.scss'
import { PichinchaTypography, PichinchaIcon } from '@pichincha/ds-react'
import { NumericFormat } from 'react-number-format'

interface NumericInputProps {
  inputValue: string
  placeholder?: string
  error?: string
  prefix?: string
  decimalScale?: number
  decimalSeparator?: string
  thousandSeparator?: string
  fixedDecimalScale?: boolean
  allowNegative?: boolean
  autofocus?: boolean
  errorHelper: string
  helperText?: string
  isError: boolean
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
  valueIsNumericString?: boolean
  type?: 'text' | 'tel' | 'password'
  onFocus?: () => void
  onBlur?: () => void
  onChange: (amount: string) => void
}

function NumericInput({
  inputValue,
  placeholder,
  prefix,
  decimalScale,
  decimalSeparator,
  thousandSeparator,
  fixedDecimalScale,
  allowNegative,
  autofocus,
  errorHelper,
  isError,
  inputMode,
  valueIsNumericString,
  type,
  onChange,
  onFocus,
  onBlur,
  helperText = 'Podrás recargar desde $0.46'
}: NumericInputProps) {
  return (
    <div data-testid="numericInputTestId" className="input-container">
      <div className="input-container__label">
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="14px"
          lineHeight="20px"
          category="text"
          color="bp-grayScale500"
          align="left"
        >
          Monto a recargar
        </PichinchaTypography>
      </div>
      <NumericFormat
        className={`input-container__input ${isError ? 'error-input' : ''}`}
        value={inputValue}
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        decimalScale={decimalScale}
        decimalSeparator={decimalSeparator}
        allowNegative={allowNegative}
        fixedDecimalScale={fixedDecimalScale}
        placeholder={placeholder}
        autoFocus={autofocus}
        inputMode={inputMode}
        valueIsNumericString={valueIsNumericString}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={(values) => {
          const { value } = values
          onChange(value)
        }}
      />
      <div className="input-container__helper">
        <PichinchaIcon
          aria-hidden={true}
          size="14px"
          customColorValue={isError ? 'bp-alert-error500' : 'bp-grayScale300'}
          type="filled"
        >
          info
        </PichinchaIcon>
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="12px"
          lineHeight="16px"
          category="text"
          color={isError ? 'bp-alert-error500' : 'bp-grayScale400'}
        >
          {isError ? errorHelper : helperText}
        </PichinchaTypography>
      </div>
    </div>
  )
}

export default NumericInput
