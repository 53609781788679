import './others-product.scss'
import { capitalizeText } from '../../../common/utils/utils'
import { PichinchaTypography } from '@pichincha/ds-react'

interface OthersProductProps {
  id: string
  title: string
  customerName: string
  product: string
  variant: 'card' | 'account'
}

function OthersProduct({ id, title, customerName, product, variant }: OthersProductProps) {
  return (
    <div data-testid={id} className="others-product">
      <PichinchaTypography
        variant="prelo"
        weight="400"
        size="14px"
        lineHeight="20px"
        category="text"
        color="bp-grayScale500"
        style={{ paddingBottom: '4px' }}
      >
        {title}
      </PichinchaTypography>
      <PichinchaTypography
        variant="prelo"
        weight="600"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
        style={{ paddingBottom: '12px' }}
      >
        {capitalizeText(customerName)}
      </PichinchaTypography>
      <PichinchaTypography
        variant="prelo"
        weight="400"
        size="14px"
        lineHeight="20px"
        category="text"
        color="bp-grayScale500"
        style={{ paddingBottom: '4px' }}
      >
        {variant === 'card' ? 'Tarjeta Ciudad Metro de Quito asociada' : 'Cuenta asociada'}
      </PichinchaTypography>
      <PichinchaTypography
        variant="prelo"
        weight="600"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
      >
        {variant === 'card' ? `Nro. ${product}` : product}
      </PichinchaTypography>
    </div>
  )
}

export default OthersProduct
