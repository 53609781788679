export interface TokenListenerEvent {
  detail: {
    softToken: string
    timeLeft: number
  }
}

export const WebviewEvents = {
  IDENTITY_APP_EVENT: 'IDENTITY_APP_EVENT',
  IDENTITY_WEB_EVENT: 'IDENTITY_WEB_EVENT',
  CUSTOM_PAYLOAD_EVENT: 'CUSTOM_PAYLOAD_EVENT',
  SOFTTOKEN_APP_EVENT: 'SOFTTOKEN_APP_EVENT',
  GO_BACK_SCREEN_EVENT: 'GO_BACK_SCREEN_EVENT',
  BW_MESSAGE: 'message'
}

export const LocalEvents = {
  HEADER_PRESS_EVENT: 'HEADER_PRESS_EVENT'
}
