import './voucher.scss'
import { useVoucher } from './use-voucher/use-voucher'
import {
  capitalizeText,
  formatDate,
  removeMoneyFormat,
  toMoneyFormat
} from '../../../common/utils/utils'
import {
  PichinchaHeaderApp,
  PichinchaSpotModule,
  PichinchaButton,
  PichinchaIcon,
  PichinchaTypography,
  PichinchaDivider,
  PichinchaCardList,
  PichinchaCardItem
} from '@pichincha/ds-react'
import DetailItem from '../../molecules/detail-item/detail-item'

const Voucher = () => {
  const {
    details,
    handleBackButton,
    handleDownloadVoucher,
    handleGoBackAppHome,
    isOther,
    paymentData,
    rechargedCard
  } = useVoucher()

  return (
    <>
      <PichinchaHeaderApp showLogo variant="lastLevel" />
      <div className="voucher" data-testid="voucherPageTestId">
        <div className="voucher__content">
          <PichinchaSpotModule
            spotTitle="Recarga realizada con éxito"
            spotDescription={`Número de comprobante: ${paymentData.voucher}`}
            variant="success"
          />
          <div className="voucher__content__information">
            <div className="voucher__content__information__amount">
              <DetailItem
                label="Monto recargado"
                value={paymentData.amount}
                isLabelBold
                isValueBold
              />
              {!isOther && (
                <>
                  <DetailItem
                    label="Saldo anterior"
                    value={toMoneyFormat(rechargedCard?.balance ?? 0)}
                  />
                  {}
                  <DetailItem
                    label="Saldo total"
                    value={toMoneyFormat(
                      removeMoneyFormat(paymentData.amount) + (rechargedCard?.balance ?? 0)
                    )}
                  />
                </>
              )}
              <DetailItem label="Costo de la transacción" value={paymentData.transactionCost} />
              <DetailItem label="Fecha" value={formatDate(paymentData.date)} />
            </div>
            <PichinchaDivider variant="solid" style={{ padding: '0.25rem' }} />
            <div className="voucher__content__information__account">
              <PichinchaTypography
                variant="prelo"
                weight="600"
                size="16px"
                lineHeight="24px"
                color="bp-grayScale500"
              >
                Cuenta origen
              </PichinchaTypography>
              <DetailItem label="Nombre" value={capitalizeText(paymentData.name)} isValueBold />
              <DetailItem label="Número de cuenta" value={paymentData.cardNumber} />
            </div>
            <PichinchaDivider variant="solid" style={{ padding: '0.25rem' }} />
            <div className="voucher__content__information__card">
              <PichinchaTypography
                variant="prelo"
                weight="600"
                size="16px"
                lineHeight="24px"
                color="bp-grayScale500"
              >
                Tarjeta recargada
              </PichinchaTypography>
              <DetailItem label="Transporte" value="Metro de Quito" isValueBold />
              <DetailItem label="Nombre" value={capitalizeText(details?.customerName ?? '')} />
              <DetailItem label="Nro. de tarjeta" value={details?.reference ?? ''} />
            </div>
            <PichinchaDivider variant="solid" style={{ padding: '0.25rem' }} />
          </div>
          <PichinchaButton customWidth="100%" size="large" onClick={handleDownloadVoucher}>
            <PichinchaIcon size="24px" cssColorValue="#0F265C" slot="icon">
              share
            </PichinchaIcon>
            <PichinchaTypography
              variant="prelo"
              weight="600"
              size="14px"
              lineHeight="24px"
              color="bp-primary-blue500"
              slot="text"
            >
              Compartir comprobante
            </PichinchaTypography>
          </PichinchaButton>
        </div>
        <div className="voucher__more-actions">
          <PichinchaTypography
            variant="prelo"
            weight="600"
            size="16px"
            lineHeight="24px"
            color="bp-grayScale500"
          >
            Otras operaciones
          </PichinchaTypography>
          <PichinchaCardList>
            <PichinchaCardItem
              labelTitle="Hacer otra recarga"
              iconName="credit_card"
              iconColor="bp-primary-blue500"
              onClick={handleBackButton}
            />
          </PichinchaCardList>
        </div>
      </div>
      <div className="voucher__footer">
        <PichinchaButton
          label="Regresar al inicio"
          variant="secondary"
          onClick={handleGoBackAppHome}
          customWidth="100%"
          size="large"
        />
      </div>
    </>
  )
}

export default Voucher
