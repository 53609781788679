import './debit-accounts.scss'
import { PichinchaSelectorAccount, PichinchaTypography } from '@pichincha/ds-react'
import { capitalizeText } from '../../../common/utils/utils'
import useDebitAccounts from './use-debit-accounts/use-debit-accounts'

const DebitAccounts = () => {
  const { accounts, handleOnClickSelector, selectedAccount } = useDebitAccounts()

  return (
    <div className="accounts-page" data-testid={'accountsPageTestId'}>
      <div className="accounts-page__body">
        <PichinchaTypography
          variant="prelo"
          weight="400"
          size="14px"
          lineHeight="20px"
          category="text"
          color="bp-grayScale500"
          align="left"
        >
          Elige la cuenta para realizar el débito
        </PichinchaTypography>
        {accounts.map((account, index) => (
          <PichinchaSelectorAccount
            key={`selector-account-${index}`}
            isSelected={account === selectedAccount}
            selectorId={`account-${index}`}
            labelTitleLeft={capitalizeText(account.alias)}
            labelDescLeft={`Nro. ${account.mask}`}
            labelTitleRight={account.balance}
            labelDescRight="Saldo disponible"
            onClickEvent={() => handleOnClickSelector(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default DebitAccounts
