import { useReducer } from 'react'
import { GlobalContext } from './global-context'
import { GlobalState } from './global-state'
import { globalReducer } from './global-reducer/global-reducer'
import { getActions } from './action-dispatcher/action-dispatcher'
import { Actions } from './actions/actions'

export const INITIAL_STATE: GlobalState = {
  authEvent: undefined,
  screenTitle: 'Recargas Metro de Quito',
  isHeaderVisible: true,
  isHeaderExpanded: false,
  isOther: false,
  selectedAccount: undefined,
  rechargeAmount: ''
}

interface GlobalProviderProps {
  children: JSX.Element | JSX.Element[]
  value?: {
    globalState?: GlobalState
    actions?: Actions
  }
}

const GlobalProvider = ({ value, children }: GlobalProviderProps) => {
  const [globalState, dispatch] = useReducer(globalReducer, INITIAL_STATE)
  const actions = getActions(dispatch)

  return (
    <GlobalContext.Provider value={{ globalState, actions, ...value }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
