import './product-information.scss'
import {
  PichinchaSectionTitleInformation,
  PichinchaTypography,
  PichinchaIcon
} from '@pichincha/ds-react'

interface ProductInformationProps {
  productTexts: {
    title: string
    description: string
    benefitsTitle: string
    benefits: {
      icon: string
      title: string
      description: string
    }[]
  }
}

function ProductInformation({ productTexts }: ProductInformationProps) {
  return (
    <div className="product-information-container">
      <div className="product-information-container__header">
        <PichinchaSectionTitleInformation
          sectionTitle={productTexts.title}
          sectionDescription={productTexts.description}
          alignElements="left"
          size="md"
          showDivider
        />
      </div>
      <div className="product-information-container__body">
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="20px"
          lineHeight="24px"
          category="text"
          color="bp-grayScale500"
          align="left"
          style={{ paddingBottom: '16px' }}
        >
          {productTexts.benefitsTitle}
        </PichinchaTypography>
        <div className="benefits-container">
          {productTexts.benefits.map((benefit, index) => (
            <div className="benefits-container__benefit" key={index}>
              <div className="benefits-container__benefit__icon">
                <PichinchaIcon size="24px" type="filled" cssColorValue="#0F265C">
                  {benefit.icon}
                </PichinchaIcon>
              </div>
              <div className="benefits-container__benefit__text">
                <PichinchaTypography
                  variant="prelo"
                  weight="600"
                  size="16px"
                  lineHeight="24px"
                  category="text"
                  color="bp-grayScale500"
                  align="left"
                >
                  {benefit.title}
                </PichinchaTypography>
                <PichinchaTypography
                  variant="prelo"
                  weight="400"
                  size="16px"
                  lineHeight="24px"
                  category="text"
                  color="bp-grayScale400"
                  align="left"
                >
                  {benefit.description}
                </PichinchaTypography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductInformation
