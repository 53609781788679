import './error-modal.scss'
import { PichinchaButton, PichinchaSpotModule } from '@pichincha/ds-react'
import { FC } from 'react'

interface ErrorModalProps {
  title: string
  description: string
  onClose: () => void
  variant?: 'error' | 'warning'
}

const ErrorModal: FC<ErrorModalProps> = ({ title, description, onClose, variant = 'error' }) => {
  return (
    <div className="error-modal" data-testid="error-modal-testid">
      <div className="error-modal__content">
        <PichinchaSpotModule variant={variant} spotTitle={title} spotDescription={description} />
      </div>
      <div className="error-modal__actions">
        <PichinchaButton customWidth="100%" label="Entendido" variant="primary" onClick={onClose} />
      </div>
    </div>
  )
}

export default ErrorModal
