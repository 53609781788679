import './onboarding.scss'
import { PichinchaButton, PichinchaDivider, PichinchaTypography } from '@pichincha/ds-react'
import onboardingMetro from '../../../assets/onboardingMetro.svg'
import Benefit from '../../molecules/benefit/benefit'
import { ONBOARDING_STEPS } from '../../../common/constants/strings'
import useOnboarding from './use-onboarding/use-onboarding'

const Onboarding = () => {
  const { handleContinue } = useOnboarding()

  return (
    <div className="onboarding" data-testid="onboarding">
      <div className="onboarding__container">
        <div className="onboarding__container__header">
          <div className="onboarding__container__header__icon">
            <img src={onboardingMetro} alt="Onboarding" />
          </div>
          <PichinchaTypography
            variant="prelo"
            weight="600"
            size="22px"
            lineHeight="28px"
            align="center"
            color="bp-primary-blue500"
          >
            ¡Ahora puedes recargar tu tarjeta del Metro de Quito!
          </PichinchaTypography>
        </div>
        <div className="onboarding__container__content">
          {ONBOARDING_STEPS.map((step) => (
            <Benefit {...step} key={step.text} />
          ))}
        </div>
      </div>
      <div className="onboarding__footer">
        <PichinchaDivider variant="solid" style={{ padding: 0, paddingBottom: '0.5rem' }} />
        <div className="onboarding__footer__button">
          <PichinchaButton
            onClick={handleContinue}
            label="Continuar"
            variant="primary"
            size="large"
            customWidth="100%"
          />
        </div>
      </div>
    </div>
  )
}

export default Onboarding
