import { RoutePath } from '../../../../common/constants/route-path'
import { useBackButton } from '../../../../hooks/use-back-button'
import { useRouterEvent } from '../../../../hooks/use-router-event'
import { useGlobalContext } from '../../../../context/use-global-context'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigateToAppScreen, pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { MessageService, WebviewMessages } from '@pichincha/channels-event-bus'
import { useQuery } from '@tanstack/react-query'
import {
  AccountsResponse,
  CustomerResponse,
  PaymentResponse
} from '../../../../models/api.interface'
import { queryClient } from '../../../../common/config/query-client'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'
import { emptyPaymentResponse } from '../../../../common/constants/mocks'

export const useVoucher = () => {
  const navigate = useNavigate()

  const {
    globalState: { authEvent, isOther },
    actions: { showHeader, setRechargeAmount }
  } = useGlobalContext()

  const { data: customerData } = useQuery<CustomerResponse>({
    queryKey: ['getCustomerData', authEvent],
    enabled: false
  })

  const { data: accountsData } = useQuery<AccountsResponse>({
    queryKey: ['getAccountsData', authEvent],
    enabled: false
  })

  const { data: paymentData } = useQuery<PaymentResponse>({
    queryKey: ['executePayment', authEvent],
    enabled: false
  })

  const rechargedCard = useMemo(() => {
    if (!customerData) return undefined
    return customerData.data.cards[0]
  }, [customerData])

  const details = useMemo(() => {
    if (!accountsData) return undefined
    return accountsData.bill.details
  }, [accountsData])

  const isOtherTag = useMemo(() => (isOther ? '_OTHER' : ''), [isOther])

  const handleBackButton = () => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.VOUCHER.GO_BACK_WEBVIEW_HOME}${isOtherTag}`
    })
    queryClient.resetQueries({
      queryKey: ['executePayment']
    })
    queryClient.resetQueries({
      queryKey: ['getAccountsData']
    })
    showHeader(true)
    setRechargeAmount('')
    window.scrollTo(0, 0)
    navigate(RoutePath.HOME)
  }

  const handleDownloadVoucher = () => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.VOUCHER.DOWNLOAD_VOUCHER}${isOtherTag}`
    })
    const message = {
      type: WebviewMessages.NATIVE_SHARE,
      shareFile: paymentData?.attachment,
      shareOptions: {
        title: 'Comprobante'
      }
    }
    MessageService.sendMessage(message, '*')
  }

  const handleGoBackAppHome = () => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.VOUCHER.GO_BACK_APP_HOME}${isOtherTag}`
    })
    navigateToAppScreen('AppHome')
  }

  useBackButton(handleBackButton)
  useRouterEvent()

  useEffect(() => {
    pushTrackEvent({
      eventName: `${TRA_VIEW_EVENTS.VOUCHER}${isOtherTag}`
    })
  }, [])

  return {
    paymentData: paymentData ?? emptyPaymentResponse,
    details,
    handleDownloadVoucher,
    handleBackButton,
    handleGoBackAppHome,
    isOther,
    rechargedCard
  }
}
