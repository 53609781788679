export const strings = {
  cardModalInformation: {
    title: '¿Qué es la Tarjeta Ciudad?',
    description:
      'La Tarjeta Ciudad es una tarjeta física recargable para usarla en el Metro y en comercios.',
    benefitsTitle: 'Beneficios',
    benefits: [
      {
        icon: 'credit_card',
        title: 'Usa el Metro sin complicaciones',
        description: 'Con tu Tarjeta Ciudad de Banco Pichincha podrás viajar en el Metro.'
      },
      {
        icon: 'emoji_flags',
        title: 'Consíguela fácilmente',
        description: 'Cómprala en cualquier Estación del Metro o Corresponsales Mi Vecino.'
      },
      {
        icon: 'attach_money',
        title: 'Recárgala donde quieras',
        description: 'Desde tu Banca Móvil, Mi Vecino y Estaciones del Metro podrás recargarla.'
      }
    ]
  }
}

export const ONBOARDING_STEPS = [
  {
    icon: 'credit_card',
    text: 'Viaja sin complicaciones en el Metro de Quito usando tu Tarjeta Ciudad.'
  },
  {
    icon: 'supervisor_account',
    text: 'Recarga con facilidad a tus amigos ingresando su número de cédula.'
  },
  {
    icon: 'attach_money',
    text: 'Sin importar dónde estés, ponle saldo a tu tarjeta desde tu Banca Móvil.'
  }
]
