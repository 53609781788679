import { useNavigate } from 'react-router-dom'
import { useIdentity } from '../../hooks/use-identity'
import { useGlobalContext } from '../../context/use-global-context'
import { useQuery } from '@tanstack/react-query'
import { viewHaveSeenOnboarding } from '../../services/customer-service/customer-service'
import { LocalEvents } from '../../models/webview-events'
import { useEffect } from 'react'
import { RoutePath } from '../../common/constants/route-path'
import { ErrorState, ViewOnboardingResponse } from '../../models/api.interface'

const useNavigation = () => {
  const navigate = useNavigate()

  useIdentity()

  const {
    globalState: { isHeaderVisible, screenTitle, isHeaderExpanded, authEvent }
  } = useGlobalContext()

  const {
    data: viewOnboardingData,
    isLoading,
    isPending
  } = useQuery<ViewOnboardingResponse, ErrorState>({
    queryKey: ['viewOnboarding', authEvent],
    queryFn: () => viewHaveSeenOnboarding(authEvent!),
    enabled: !!authEvent
  })

  const onBackClick = () => {
    document.dispatchEvent(new CustomEvent(LocalEvents.HEADER_PRESS_EVENT))
  }

  useEffect(() => {
    if (viewOnboardingData && !viewOnboardingData.checkOnboarding) navigate(RoutePath.ONBOARDING)
  }, [viewOnboardingData])

  return {
    isLoading: isLoading || isPending,
    isHeaderVisible,
    screenTitle,
    isHeaderExpanded,
    onBackClick
  }
}

export default useNavigation
