import { ActionTypes } from '../../models/action-types'
import { GlobalState } from '../global-state'

export interface GlobalAction {
  type: string
  payload: any
}

export const globalReducer = (state: GlobalState, action: GlobalAction): GlobalState => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE:
      return {
        ...state,
        authEvent: action.payload.authEvent
      }
    case ActionTypes.SET_TITLE:
      return {
        ...state,
        screenTitle: action.payload.title
      }
    case ActionTypes.SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload.account
      }
    case ActionTypes.SET_RECHARGE_AMOUNT:
      return {
        ...state,
        rechargeAmount: action.payload.amount
      }
    case ActionTypes.SHOW_HEADER:
      return {
        ...state,
        isHeaderVisible: action.payload.isHeaderVisible
      }
    case ActionTypes.EXPAND_HEADER:
      return {
        ...state,
        isHeaderExpanded: action.payload.isHeaderExpanded
      }
    case ActionTypes.SET_IS_OTHER:
      return {
        ...state,
        isOther: action.payload.isOther
      }
    default:
      return state
  }
}
