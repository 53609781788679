import './error-page.scss'
import errorState from '../../../assets/error-state.svg'
import { PichinchaButton, PichinchaTypography } from '@pichincha/ds-react'
import { useGlobalContext } from '../../../context/use-global-context'
import { useEffect } from 'react'

export interface ErrorScreenProps {
  fetchDataAgain: () => void
  title?: string
  description?: string
  icon?: string
  errorButtonText?: string
}

const ErrorPage = ({
  fetchDataAgain,
  title = 'Ocurrió un error en el servicio',
  description = 'Lo sentimos, tuvimos un inconveniente, por favor inténtalo nuevamente.',
  icon = errorState,
  errorButtonText = 'Entendido'
}: ErrorScreenProps) => {
  const {
    actions: { expandHeader, setTitle }
  } = useGlobalContext()

  useEffect(() => {
    setTitle('Recargas transporte')
    if (expandHeader) {
      expandHeader(false)
    }
  }, [])

  return (
    <div data-testid="errorPageTestId" className="error-page-container">
      <div className="error-container">
        <div className="error-body">
          <div className="error-button-icon" onClick={fetchDataAgain}>
            <img alt="Icon Error" src={icon} />
          </div>
          <div className="error-title">
            <PichinchaTypography
              variant="prelo"
              weight="600"
              color="bp-primary-blue500"
              size="20px"
              lineHeight="24px"
              category="text"
            >
              {title}
            </PichinchaTypography>
          </div>
          <div className="error-subtitle">
            <PichinchaTypography
              variant="prelo"
              weight="400"
              size="16px"
              lineHeight="24px"
              category="text"
              color="bp-grayScale500"
            >
              {description}
            </PichinchaTypography>
          </div>
        </div>
        <div className="error-button-container">
          <PichinchaButton
            data-testid={'errorPageButtonTestId'}
            label={errorButtonText}
            variant="primary"
            size="large"
            customWidth="100%"
            onClick={fetchDataAgain}
          />
        </div>
      </div>
    </div>
  )
}

export { ErrorPage }
