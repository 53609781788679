import './benefit.scss'
import { PichinchaIcon, PichinchaTypography } from '@pichincha/ds-react'
import { FC } from 'react'

interface BenefitProps {
  icon: string
  text: string
}

const Benefit: FC<BenefitProps> = ({ icon, text }) => {
  return (
    <div className="benefit">
      <div className="benefit__icon">
        <PichinchaIcon size="24px" cssColorValue="#0F265C">
          {icon}
        </PichinchaIcon>
      </div>
      <PichinchaTypography
        variant="prelo"
        weight="400"
        size="16px"
        lineHeight="24px"
        color="bp-grayScale400"
      >
        {text}
      </PichinchaTypography>
    </div>
  )
}

export default Benefit
