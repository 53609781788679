import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../../../context/use-global-context'
import { useBackButton } from '../../../../hooks/use-back-button'
import { useRouterEvent } from '../../../../hooks/use-router-event'
import { useQuery } from '@tanstack/react-query'
import { AccountsResponse } from '../../../../models/api.interface'
import { getAccountsFromApi } from '../../../../services/accounts-service/accounts-service'
import { RoutePath } from '../../../../common/constants/route-path'
import { pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'

const useDebitAccounts = () => {
  const navigate = useNavigate()

  const {
    globalState: { authEvent, selectedAccount, isOther },
    actions: { setTitle, setSelectedAccount }
  } = useGlobalContext()

  const { data: accountsData } = useQuery<AccountsResponse>({
    queryKey: ['getAccountsData', authEvent],
    queryFn: () => getAccountsFromApi(authEvent!),
    enabled: false
  })

  const accounts = useMemo(() => {
    if (!accountsData) return []
    return accountsData.accounts
  }, [accountsData])

  const isOtherTag = useMemo(() => (isOther ? '_OTHER' : ''), [isOther])

  const handleBackButton = () => {
    window.scrollTo(0, 0)
    navigate(RoutePath.RECHARGE)
  }

  const handleOnClickSelector = (index: number) => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.DEBIT_ACCOUNTS.SELECT_ACCOUNT}${isOtherTag}`
    })
    setSelectedAccount(accounts[index])
    window.scrollTo(0, 0)
    navigate(RoutePath.RECHARGE)
  }

  useBackButton(handleBackButton)
  useRouterEvent()

  useEffect(() => {
    setTitle('Mis cuentas')
  }, [])

  useEffect(() => {
    pushTrackEvent({
      eventName: `${TRA_VIEW_EVENTS.DEBIT_ACCOUNTS}${isOtherTag}`
    })
  }, [])

  return {
    accounts,
    selectedAccount,
    handleOnClickSelector
  }
}

export default useDebitAccounts
