import { BrowserRouter } from 'react-router-dom'
import './app.scss'
import GlobalProvider from './context/global-provider'
import { Navigation } from './navigation/navigation'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './common/config/query-client'

function App() {
  return (
    <div data-testid="webviews-app" className="app-container" id="app-container">
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <BrowserRouter>
            <Navigation />
          </BrowserRouter>
        </GlobalProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
