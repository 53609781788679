import { useGlobalContext } from '../../../../context/use-global-context'
import { useEffect, useMemo, useState } from 'react'
import { useBackButton } from '../../../../hooks/use-back-button'
import { navigateToAppScreen, pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { useRouterEvent } from '../../../../hooks/use-router-event'
import { useNavigate } from 'react-router-dom'
import { RoutePath } from '../../../../common/constants/route-path'
import { useQuery } from '@tanstack/react-query'
import { getConsolidatePositionFromApi } from '../../../../services/customer-service/customer-service'
import { CustomerResponse } from '../../../../models/api.interface'
import { queryClient } from '../../../../common/config/query-client'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'

const useHome = () => {
  const [openModal, setOpenModal] = useState(false)

  const navigate = useNavigate()

  const {
    globalState: { authEvent },
    actions: { setTitle, expandHeader, setIsOther }
  } = useGlobalContext()

  const {
    data: customerData,
    isError: isErrorConsolidate,
    isLoading,
    isPending,
    refetch
  } = useQuery<CustomerResponse>({
    queryKey: ['getCustomerData', authEvent],
    queryFn: () => getConsolidatePositionFromApi(authEvent!),
    enabled: !!authEvent
  })

  const card = useMemo(() => {
    if (!customerData) return undefined
    return customerData.data.cards[0]
  }, [customerData])

  const cards = useMemo(() => {
    if (!customerData) return []
    return customerData.data.cards
  }, [customerData])

  const navigateToRecharge = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.HOME.TAP_RECHARGE
    })
    setIsOther(false)
    window.scrollTo(0, 0)
    navigate(RoutePath.RECHARGE)
  }

  const navigateToOthers = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.HOME.TAP_OTHERS
    })
    window.scrollTo(0, 0)
    queryClient.resetQueries({
      queryKey: ['getAccountsData']
    })
    setIsOther(true)
    navigate(RoutePath.OTHERS)
  }

  const handleCloseModal = () => {
    pushTrackEvent({
      eventName: openModal
        ? TRA_TRACKING_EVENTS.HOME.CLOSE_MODAL
        : TRA_TRACKING_EVENTS.HOME.OPEN_MODAL
    })
    setOpenModal(!openModal)
  }

  const retryGetConsolidate = () => {
    refetch()
  }

  const backButtonAction = () => {
    navigateToAppScreen('AppHome')
  }

  useBackButton(backButtonAction)
  useRouterEvent()

  useEffect(() => {
    setTitle('Recargas Metro de Quito')
    if (expandHeader) {
      expandHeader(true)
    }
    return () => {
      expandHeader(false)
    }
  }, [])

  useEffect(() => {
    pushTrackEvent({
      eventName: TRA_VIEW_EVENTS.HOME
    })
  }, [])

  return {
    card,
    cards,
    openModal,
    navigateToRecharge,
    navigateToOthers,
    backButtonAction,
    handleCloseModal,
    retryGetConsolidate,
    isErrorConsolidate,
    isLoadingConsolidate: isLoading || isPending
  }
}

export default useHome
