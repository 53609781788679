import { useState } from 'react'
import { PichinchaTypography } from '@pichincha/ds-react'
import './chip-buttons.scss'
import ChipButton from '../../atoms/chip-button/chip-button'
import { pushTrackEvent } from '../../../common/app-messages/app-messages'
import { TRA_TRACKING_EVENTS } from '../../../common/constants/tracking-events'

export interface ChipButtonsProps {
  id: string
  data: string[]
  images?: string[]
  checked?: number
  label?: string
  symbol?: string
  onClick: (text: string) => void
}

function ChipButtons({ id, data, images, onClick, checked, label, symbol }: ChipButtonsProps) {
  const [indexChecked, setIndexChecked] = useState(checked)

  const onChipCheck = (text: string, index: number) => {
    pushTrackEvent({
      eventName: `${TRA_TRACKING_EVENTS.RECHARGE.TAP_CHIP}_${text}`
    })
    setIndexChecked(index)
    onClick(text)
  }

  return (
    <div
      id={id}
      data-testid={images ? 'chipButtonsImagesTestId' : 'chipButtonsTestId'}
      className="chip-buttons-parent-container"
    >
      <div className="label-chip-buttons">
        <PichinchaTypography
          variant="prelo"
          weight="500"
          size="16px"
          lineHeight="24px"
          category="text"
          color="bp-grayScale500"
        >
          {label}
        </PichinchaTypography>
      </div>
      <div className="chip-buttons-container">
        {data.map((text, index) => (
          <ChipButton
            index={index}
            isChecked={index === indexChecked}
            length={data.length}
            onClickChip={() => onChipCheck(text, index)}
            text={text}
            images={images}
            key={`chipButton${index}`}
            symbol={symbol}
          />
        ))}
      </div>
    </div>
  )
}

export default ChipButtons
