import { AuthWebviewEvent } from '../../models/auth-webview-event'
import { ActionTypes } from '../../models/action-types'
import { Account } from '../../models/api.interface'

export const authenticate = (authEvent: AuthWebviewEvent) => ({
  type: ActionTypes.AUTHENTICATE,
  payload: { authEvent }
})

export const setTitle = (title: string) => ({
  type: ActionTypes.SET_TITLE,
  payload: { title }
})

export const showHeader = (isHeaderVisible: boolean) => ({
  type: ActionTypes.SHOW_HEADER,
  payload: { isHeaderVisible }
})

export const expandHeader = (isHeaderExpanded: boolean) => ({
  type: ActionTypes.EXPAND_HEADER,
  payload: { isHeaderExpanded }
})

export const setIsOther = (isOther: boolean) => ({
  type: ActionTypes.SET_IS_OTHER,
  payload: { isOther }
})

export const setSelectedAccount = (account: Account) => ({
  type: ActionTypes.SET_SELECTED_ACCOUNT,
  payload: { account }
})

export const setRechargeAmount = (amount: string) => ({
  type: ActionTypes.SET_RECHARGE_AMOUNT,
  payload: { amount }
})

export interface Actions {
  authenticate: (authEvent: AuthWebviewEvent) => void
  setTitle: (title: string) => void
  showHeader: (isHeaderVisible: boolean) => void
  expandHeader: (isHeaderExpanded: boolean) => void
  setIsOther: (isOther: boolean) => void
  setSelectedAccount: (acount: Account) => void
  setRechargeAmount: (amount: string) => void
}
