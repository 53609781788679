import { TRA_SERVICE_EVENTS } from '../../common/constants/tracking-events'
import { consolidateCustomHttpRequest } from '../../common/utils/utils'
import { CustomerResponse, ViewOnboardingResponse } from '../../models/api.interface'
import { AuthWebviewEvent } from '../../models/auth-webview-event'

const BASE_URL = process.env.REACT_APP_BASE_URL as string
const CARD_URL = process.env.REACT_APP_CARD_DATA_URL as string
const VIEW_URL = process.env.REACT_APP_VIEW_ONBOARDING as string
const UPDATE_URL = process.env.REACT_APP_UPDATE_ONBOARDING as string

export const getConsolidatePositionFromApi = async (authEvent: AuthWebviewEvent) => {
  return consolidateCustomHttpRequest<CustomerResponse>({
    authEvent,
    events: {
      error: TRA_SERVICE_EVENTS.CUSTOMER.ERROR,
      success: TRA_SERVICE_EVENTS.CUSTOMER.SUCCESS
    },
    url: `${BASE_URL}${CARD_URL}`
  })
}

export const viewHaveSeenOnboarding = async (authEvent: AuthWebviewEvent) => {
  const data = await consolidateCustomHttpRequest<boolean>({
    authEvent,
    events: {
      success: TRA_SERVICE_EVENTS.VIEW_ONBOARDING.SUCCESS,
      error: TRA_SERVICE_EVENTS.VIEW_ONBOARDING.ERROR
    },
    url: `${BASE_URL}${VIEW_URL}`
  })

  return {
    checkOnboarding: data
  } as ViewOnboardingResponse
}

export const updateHaveSeenOnboarding = async (authEvent: AuthWebviewEvent) => {
  return consolidateCustomHttpRequest({
    authEvent,
    events: {
      error: TRA_SERVICE_EVENTS.UPDATE_ONBOARDING.ERROR,
      success: TRA_SERVICE_EVENTS.UPDATE_ONBOARDING.SUCCESS
    },
    url: `${BASE_URL}${UPDATE_URL}`,
    extraData: {
      checkOnBoarding: true
    }
  })
}
