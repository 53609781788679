import { AuthWebviewEvent } from '../../models/auth-webview-event'
import { PaymentExtraParams, PaymentResponse } from '../../models/api.interface'
import { customHttpRequest } from '../../common/utils/utils'
import { TRA_SERVICE_EVENTS } from '../../common/constants/tracking-events'

const BASE_URL = process.env.REACT_APP_BASE_URL as string
const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL as string

export const executePaymentFromApi = async (
  authEvent: AuthWebviewEvent,
  extraParams: PaymentExtraParams
) => {
  const { softoken, isOther, ...billingParams } = extraParams

  const otherTag = isOther ? '_OTHER' : ''

  return customHttpRequest<PaymentResponse>({
    authEvent,
    url: `${BASE_URL}${PAYMENT_URL}`,
    events: {
      success: `${TRA_SERVICE_EVENTS.PAYMENT.SUCCESS}${otherTag}`,
      error: `${TRA_SERVICE_EVENTS.PAYMENT.ERROR}${otherTag}`
    },
    extraData: {
      ...billingParams
    },
    extraHeaders: {
      'x-soft-token': softoken
    }
  })
}
