import { MessageService, TokenService, WebviewMessages } from '@pichincha/channels-event-bus'
import { useEffect, useState } from 'react'
import { TokenListenerEvent } from '../models/webview-events'

export const useTokenListener = () => {
  const [tokenData, setTokenData] = useState({
    softToken: '',
    timeLeft: 0
  })

  const onSendToken = (event: TokenListenerEvent) => {
    const {
      detail: { softToken, timeLeft }
    } = event
    setTokenData({ softToken, timeLeft })
  }

  useEffect(() => {
    try {
      MessageService.sendMessage({ type: WebviewMessages.SOFTTOKEN_EVENT_REQUEST }, '*')
      TokenService.subscribeToTokenEvent(onSendToken)
    } catch (error) {}

    return () => {
      TokenService.unsubscribeToTokenEvent()
      MessageService.sendMessage(
        {
          type: WebviewMessages.SOFTTOKEN_EVENT_CANCEL
        },
        '*'
      )
    }
  }, [])

  return {
    ...tokenData
  }
}
