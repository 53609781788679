import './recharge-detail.scss'
import { PichinchaTypography } from '@pichincha/ds-react'

interface RechargeDetailProps {
  rechargeAmount: string
  productName: string
  productNumber: string
  accountNumber: string
  accountName: string
}

function RechargeDetail({
  rechargeAmount,
  productName,
  productNumber,
  accountNumber,
  accountName
}: RechargeDetailProps) {
  return (
    <div className="recharge-detail">
      <PichinchaTypography
        variant="prelo"
        weight="600"
        size="18px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
        align="center"
        style={{ paddingBottom: '16px' }}
      >
        Monto a recargar: $ {rechargeAmount}
      </PichinchaTypography>
      <PichinchaTypography
        variant="prelo"
        weight="600"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
        align="center"
        style={{ paddingBottom: '4px' }}
      >
        A la tarjeta
      </PichinchaTypography>
      <div className="recharge-detail__card-info">
        <PichinchaTypography
          variant="prelo"
          weight="400"
          size={'16px'}
          lineHeight={'24px'}
          category="text"
          color="bp-grayScale500"
        >
          {productName}
        </PichinchaTypography>
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="16px"
          lineHeight="24px"
          category="text"
          color="bp-secondary-darkGrayishBlue300"
        >
          |
        </PichinchaTypography>
        <PichinchaTypography
          variant="prelo"
          weight="400"
          size="16px"
          lineHeight="24px"
          category="text"
          color="bp-grayScale500"
        >
          {productNumber}
        </PichinchaTypography>
      </div>
      <PichinchaTypography
        variant="prelo"
        weight="600"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
        align="center"
        style={{ paddingBottom: '4px' }}
      >
        De la cuenta
      </PichinchaTypography>
      <div className="recharge-detail__payment-info">
        <PichinchaTypography
          variant="prelo"
          weight="400"
          size={'16px'}
          lineHeight={'24px'}
          category="text"
          color="bp-grayScale500"
        >
          {accountName}
        </PichinchaTypography>
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="16px"
          lineHeight="24px"
          category="text"
          color="bp-secondary-darkGrayishBlue300"
        >
          |
        </PichinchaTypography>
        <PichinchaTypography
          variant="prelo"
          weight="400"
          size="16px"
          lineHeight="24px"
          category="text"
          color="bp-grayScale500"
        >
          {accountNumber}
        </PichinchaTypography>
      </div>
      <PichinchaTypography
        variant="prelo"
        weight="400"
        size="16px"
        lineHeight="24px"
        category="text"
        color="bp-grayScale500"
        align="center"
      >
        Esta transacción no tiene costo
      </PichinchaTypography>
    </div>
  )
}

export default RechargeDetail
