import { useEffect } from 'react'
import { isValid } from '../common/utils/utils'
import { useGlobalContext } from '../context/use-global-context'
import { WebviewEvents } from '../models/webview-events'

export const useIdentity = () => {
  const {
    actions: { authenticate }
  } = useGlobalContext()
  const { IDENTITY_APP_EVENT } = WebviewEvents

  const appIdentityListener = (event: any) => {
    const { detail } = event
    const { clientId, clientIdType, device, guid, ip, jwtToken, session } = detail

    const isValidAuthEvent =
      isValid(clientId) &&
      isValid(clientIdType) &&
      isValid(device) &&
      isValid(guid) &&
      isValid(ip) &&
      isValid(jwtToken) &&
      isValid(session)
    if (isValidAuthEvent) {
      authenticate(detail)
    }
  }

  useEffect(() => {
    document.addEventListener(IDENTITY_APP_EVENT, appIdentityListener)

    return () => {
      document.removeEventListener(IDENTITY_APP_EVENT, appIdentityListener)
    }
  })
}
