import { useQuery } from '@tanstack/react-query'
import { useGlobalContext } from '../../../../context/use-global-context'
import { useNavigate } from 'react-router-dom'
import { updateHaveSeenOnboarding } from '../../../../services/customer-service/customer-service'
import { RoutePath } from '../../../../common/constants/route-path'
import { navigateToAppScreen, pushTrackEvent } from '../../../../common/app-messages/app-messages'
import { useBackButton } from '../../../../hooks/use-back-button'
import { useEffect } from 'react'
import { TRA_TRACKING_EVENTS, TRA_VIEW_EVENTS } from '../../../../common/constants/tracking-events'

const useOnboarding = () => {
  const navigate = useNavigate()

  const {
    globalState: { authEvent },
    actions: { setTitle, expandHeader }
  } = useGlobalContext()

  const { refetch } = useQuery({
    queryKey: ['updateOnboarding', authEvent],
    queryFn: () => updateHaveSeenOnboarding(authEvent!),
    enabled: false
  })

  const handleContinue = () => {
    pushTrackEvent({
      eventName: TRA_TRACKING_EVENTS.ONBOARDING.TAP_CONTINUE
    })
    refetch()
    navigate(RoutePath.HOME)
  }

  const goBack = () => {
    navigateToAppScreen('AppHome')
  }

  useBackButton(goBack)

  useEffect(() => {
    setTitle('Recargas Transporte')
    expandHeader(false)
  }, [])

  useEffect(() => {
    pushTrackEvent({
      eventName: TRA_VIEW_EVENTS.ONBOARDING
    })
  }, [])

  return {
    handleContinue,
    goBack
  }
}

export default useOnboarding
