export const WebviewMessages = {
  ROUTER_EVENT: 'ROUTER_EVENT',
  GO_BACK_HOME: 'GO_BACK_HOME',
  SOFTTOKEN_EVENT_REQUEST: 'SOFTTOKEN_EVENT_REQUEST',
  SOFTTOKEN_EVENT_CANCEL: 'SOFTTOKEN_EVENT_CANCEL',
  LOGOUT: 'APP_LOGOUT',
  NAVIGATE_TO_SCREEN: 'NAVIGATE_TO_SCREEN',
  OPEN_SCREEN: 'OPEN_SCREEN',
  OPEN_BROWSER: 'OPEN_BROWSER',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  NATIVE_SHARE: 'NATIVE_SHARE',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  UPDATE_IS_VISIBLE_AMOUNT_IN_LOCAL_STORAGE: 'UPDATE_IS_VISIBLE_AMOUNT_IN_LOCAL_STORAGE',
  VALIDATE_ONBOARDING_BEFORE_TRANSACTING: 'VALIDATE_ONBOARDING_BEFORE_TRANSACTING',
  PUSH_TRACK_EVENT: 'PUSH_TRACK_EVENT',
  CUSTOM_PAYLOAD_EVENT: 'CUSTOM_PAYLOAD_EVENT'
}
