import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { send } from '../common/app-messages/app-messages'
import { WebviewMessages } from '../models/webview-messages'

export const useRouterEvent = () => {
  const location = useLocation()

  useEffect(() => {
    const message = {
      type: WebviewMessages.ROUTER_EVENT,
      url: location.pathname
    }
    send(message)
  }, [location])
}
