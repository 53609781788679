import './others.scss'
import {
  PichinchaButton,
  PichinchaDivider,
  PichinchaInput,
  PichinchaEmptyState,
  PichinchaTypography
} from '@pichincha/ds-react'
import useOthers from './use-others/use-others'
import { EMPTY_STATE_TYPES } from '@pichincha/ds-core'
import OthersProduct from '../../molecules/others-product/others-product'
import OthersProductSkeleton from '../../templates/others-product-skeleton/others-product-skeleton'
import { ErrorPage } from '../error-page/error-page'
import Modal from '../../organisms/modal/modal'
import ErrorModal from '../../molecules/error-modal/error-modal'
import { ERRORS } from '../../../common/constants/errors'

export const Others = () => {
  const {
    accountsData,
    closeErrorModal,
    error,
    getOthersCustomerData,
    handleInputChange,
    inputValue,
    isError,
    isErrorOthersData,
    isLoading,
    navigateToRecharge,
    resetOthersScreen,
    showErrorModal
  } = useOthers()

  const renderOthersCard = () => {
    if (accountsData.bill.details?.customerName && !isLoading && !isErrorOthersData) {
      return (
        <OthersProduct
          id="othersCardTestId"
          title="Esta cédula pertenece a:"
          customerName={accountsData.bill.details.customerName}
          product={accountsData.bill.details.reference}
          variant="card"
        />
      )
    }
    if (!accountsData.bill.details && !isLoading) {
      return (
        <PichinchaEmptyState
          data-testid="emptyStateTestId"
          titleText="Sin tarjetas para recargar"
          descriptionText="Este usuario no posee una tarjeta disponible para hacer una recarga"
          type={'card' as EMPTY_STATE_TYPES}
          iconName="credit_card"
          iconProps={{ size: '24px' }}
          style={{ paddingBottom: '16px' }}
          linkButtonProps={{ label: '' }}
        />
      )
    }
    if (isLoading) {
      return <OthersProductSkeleton />
    }
  }

  if (isErrorOthersData && error?.code !== ERRORS.ERROR_412) {
    return <ErrorPage fetchDataAgain={resetOthersScreen.bind(this, true)} />
  }
  return (
    <div className="others-page" data-testid="rechargePageTestId">
      <div className="others-page__body">
        <PichinchaTypography
          variant="prelo"
          weight="600"
          size="14px"
          lineHeight="16px"
          color="bp-grayScale500"
        >
          Cédula de quién recibirá la recarga
        </PichinchaTypography>
        <div className="others-page__body__verification">
          <div className="others-page__body__verification__input">
            <PichinchaInput
              data-testid="documentNumberInputTestId"
              maxCharacters={10}
              type="number"
              value={inputValue}
              onInputChange={(value) => handleInputChange(value.detail.value)}
              state={isError ? 'error' : 'success'}
              errorHelper="Revisa que la cédula sea correcta"
              showRightIcon={false}
              showMaxCharacters
            />
          </div>
          <PichinchaButton
            data-testid="validateDniButtonTestId"
            label={'Validar cédula'}
            variant="primary"
            size="small"
            onClick={getOthersCustomerData}
            disabled={isError || inputValue.trim() === ''}
          />
        </div>
        <div className="others-page__body__information">{renderOthersCard()}</div>
      </div>
      <div className="others-page__footer">
        <PichinchaDivider variant="solid" style={{ padding: 0 }} />
        <div className="others-page__footer__button">
          <PichinchaButton
            data-testid="othersContinueButtonId"
            label="Continuar"
            variant="primary"
            size="large"
            customWidth="100%"
            onClick={navigateToRecharge}
            disabled={!accountsData.bill.details?.reference}
          />
        </div>
      </div>
      {showErrorModal && (
        <Modal onClose={closeErrorModal} center>
          <ErrorModal
            title="Sin tarjetas para recargar"
            description="Este usuario no posee una tarjeta disponible para hacer una recarga"
            onClose={closeErrorModal}
          />
        </Modal>
      )}
    </div>
  )
}

export default Others
