import { useEffect } from 'react'
import { WebviewEvents, LocalEvents } from '../models/webview-events'

export const useBackButton = (onBackButtonPress: () => void) => {
  const { GO_BACK_SCREEN_EVENT } = WebviewEvents
  const { HEADER_PRESS_EVENT } = LocalEvents

  useEffect(() => {
    document.addEventListener(GO_BACK_SCREEN_EVENT, onBackButtonPress)
    document.addEventListener(HEADER_PRESS_EVENT, onBackButtonPress)
    return () => {
      document.removeEventListener(GO_BACK_SCREEN_EVENT, onBackButtonPress)
      document.removeEventListener(HEADER_PRESS_EVENT, onBackButtonPress)
    }
  })
}
